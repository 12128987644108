import ArrayProxy from '@ember/array/proxy';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import EpicModel from 'kancast-ui/models/epic';
import ProjectModel from 'kancast-ui/models/project';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';

export default class FinishedEpicsController extends Controller {
  @service
  store!: emberData__store;

  pageSize = 10;

  @tracked
  page = 1;

  @tracked
  epics?: ArrayProxy<EpicModel> & { meta?: { total?: number } };

  model!: ProjectModel;

  @tracked
  epicToEdit?: EpicModel;

  fetchEpics = restartableTask(async () => {
    this.epics = await this.store.query('epic', {
      filter: {
        project_id: this.model.id,
        status: 'finished',
        tenant_id: this.model.tenant.id,
      },
      sort: '-finished_at',
      page: { number: this.page, size: this.pageSize },
      include: 'reporter,tickets',
    });
  });

  get total() {
    return this.epics?.meta?.total ?? 0;
  }

  @action
  changePage(pageNumber: number) {
    this.page = pageNumber;
    this.fetchEpics.perform();
  }
}
