import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsModalSimple\n  data-test-start-ticket-modal\n  @open={{@open}}\n  @title=\"Create a ticket\"\n  @closeTitle=\"Cancel\"\n  @submitTitle={{\"Save\"}}\n  @size=\"lg\"\n  @onHidden={{@onHidden}}\n  @onSubmit={{fn (perform this.submit) this.changeset}}\n>\n  <TicketForm\n    @changeset={{this.changeset}}\n    @epics={{this.epics}}\n    @onSubmit={{fn (perform this.submit) this.changeset}}\n  />\n</BsModalSimple>", {"contents":"<BsModalSimple\n  data-test-start-ticket-modal\n  @open={{@open}}\n  @title=\"Create a ticket\"\n  @closeTitle=\"Cancel\"\n  @submitTitle={{\"Save\"}}\n  @size=\"lg\"\n  @onHidden={{@onHidden}}\n  @onSubmit={{fn (perform this.submit) this.changeset}}\n>\n  <TicketForm\n    @changeset={{this.changeset}}\n    @epics={{this.epics}}\n    @onSubmit={{fn (perform this.submit) this.changeset}}\n  />\n</BsModalSimple>","moduleName":"kancast-ui/components/start-ticket-modal.hbs","parseOptions":{"srcName":"kancast-ui/components/start-ticket-modal.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  map,
  pipe,
  prop,
  sortBy,
  head,
  pick,
  merge,
  filter,
  complement,
  isEmpty,
} from 'ramda';
import { dropTask } from 'ember-concurrency';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import TicketModel, { Estimation } from 'kancast-ui/models/ticket';
import { BufferedChangeset } from 'ember-changeset/types';
import ProjectModel from 'kancast-ui/models/project';
import { NotificationsService } from 'services/notifications';
import { validatePresence } from 'ember-changeset-validations/validators';
import EpicModel from 'kancast-ui/models/epic';
import ArrayProxy from '@ember/array/proxy';

const notEmpty = complement(isEmpty);

const buildCustomFields: (project: ProjectModel) => Record<'content', null>[] =
  pipe(
    prop('customFields'),
    map(pipe(pick(['name', 'content_type']), merge({ content: null })))
  );
const buildEstimations: (project: ProjectModel) => Estimation[] = pipe(
  prop('estimationCriteria'),
  filter(notEmpty),
  map((criterionName) => new Estimation(criterionName, 0))
);

const getInitialStatus = pipe(
  prop('ticketBuckets'),
  sortBy(prop('position')),
  head,
  prop('name')
);

const Validations = {
  title: validatePresence(true),
};

interface Args {
  project: ProjectModel;
  epic?: EpicModel;
  isInitialStatusNull?: boolean;
  afterSave(ticket: TicketModel): unknown;
}

export default class extends Component<Args> {
  @service
  store!: Store;

  @service
  notifications!: NotificationsService;

  @tracked
  newTicket!: TicketModel;

  @tracked
  changeset!: BufferedChangeset;

  epics?: ArrayProxy<EpicModel>;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.setNewTicket();

    if (!args.epic)
      this.epics = this.store.query('epic', {
        filter: {
          project_id: args.project.id,
          active: true,
          tenant_id: args.project.tenant.id,
        },
      });
  }

  submit = dropTask(async (changeset: BufferedChangeset) => {
    try {
      changeset.set('sortOrder', this.nextSortOrder);
      await changeset.validate();
      await changeset.save();
      this.setNewTicket();
      this.args.afterSave(this.newTicket);
    } catch (e) {
      this.notifications.error('Something went wrong');
      throw e;
    }
  });

  setNewTicket() {
    const { project, epic, isInitialStatusNull } = this.args;
    this.newTicket = this.store.createRecord('ticket', {
      project,
      epic,
      status: isInitialStatusNull ? null : getInitialStatus(project),
      estimations: buildEstimations(project),
      customFields: buildCustomFields(project),
    });
    this.changeset = Changeset(
      this.newTicket,
      lookupValidator(Validations),
      Validations
    );
  }

  get nextSortOrder() {
    const maxSortOrder = Math.max(
      ...this.store
        .peekAll('ticket')
        .filter(
          (t) =>
            !t.isNew && !t.isDeleted && t.project?.id === this.args.project.id
        )
        .map((t) => t.sortOrder)
    );

    return Math.max(maxSortOrder, 0) + 100;
  }
}
