import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import TenantModel from 'kancast-ui/models/tenant';
import { AbilitiesService } from 'services/abilities';

export default class IndexRoute extends Route {
  @service
  abilities!: AbilitiesService;

  beforeModel() {
    const tenant: TenantModel = this.modelFor('tenant');
    if (this.abilities.can('manage tenant', tenant)) return;
    this.transitionTo('tenant.401');
  }
}
