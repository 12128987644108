import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsForm\n  @model={{this.changeset}}\n  @onSubmit={{perform this.save}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-invite-email\n    @controlType=\"text\"\n    @label=\"Email\"\n    @property=\"email\"\n  />\n\n  <form.element\n    data-test-invite-role\n    @controlType=\"power-select\"\n    @label=\"Role\"\n    @property=\"role\"\n    @options={{this.roles}}\n  />\n\n  <h4>\n    Project memberships\n  </h4>\n\n  {{#each this.projectMemberships as |projectMembership|}}\n    <Invites::ProjectMembershipForm @projectMembership={{projectMembership}} />\n  {{/each}}\n\n  <hr />\n  <form.submitButton data-test-save-invite>\n    Send invite\n  </form.submitButton>\n</BsForm>", {"contents":"<BsForm\n  @model={{this.changeset}}\n  @onSubmit={{perform this.save}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-invite-email\n    @controlType=\"text\"\n    @label=\"Email\"\n    @property=\"email\"\n  />\n\n  <form.element\n    data-test-invite-role\n    @controlType=\"power-select\"\n    @label=\"Role\"\n    @property=\"role\"\n    @options={{this.roles}}\n  />\n\n  <h4>\n    Project memberships\n  </h4>\n\n  {{#each this.projectMemberships as |projectMembership|}}\n    <Invites::ProjectMembershipForm @projectMembership={{projectMembership}} />\n  {{/each}}\n\n  <hr />\n  <form.submitButton data-test-save-invite>\n    Send invite\n  </form.submitButton>\n</BsForm>","moduleName":"kancast-ui/components/invites/form.hbs","parseOptions":{"srcName":"kancast-ui/components/invites/form.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import InviteModel from 'kancast-ui/models/invite';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import {
  validatePresence,
  validateFormat,
  validateExclusion,
} from 'ember-changeset-validations/validators';
import { dropTask } from 'ember-concurrency';
import { BufferedChangeset } from 'ember-changeset/types';
import ProjectModel from 'kancast-ui/models/project';
import { isNil, pipe, prop } from 'ramda';

interface Args {
  invite: InviteModel;
  usedEmails: string[];
  projects: ProjectModel[];
  afterSave: Function;
}

export class InviteProjectMembership {
  project: ProjectModel;

  @tracked
  role = 'member';

  constructor(project: ProjectModel) {
    this.project = project;
  }
}

export default class extends Component<Args> {
  @tracked
  changeset: BufferedChangeset;

  roles = Object.freeze(['admin', 'teammate']);

  projectMemberships: InviteProjectMembership[];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const validations = {
      email: [
        validatePresence(true),
        validateFormat({ type: 'email' }),
        validateExclusion({
          list: args.usedEmails,
          message: 'the email is already in used',
        }),
      ],
      role: [validatePresence(true)],
    };

    this.changeset = Changeset(
      args.invite,
      lookupValidator(validations),
      validations
    );

    this.projectMemberships = args.projects.map(
      (p) => new InviteProjectMembership(p)
    );
  }

  save = dropTask(async () => {
    await this.changeset.validate;

    if (this.changeset.isInvalid) return;

    this.changeset.set(
      'projectMemberships',
      this.projectMemberships
        .reject(pipe(prop('role'), isNil))
        .map(({ role, project }) => ({ role, project_id: project.id }))
    );

    await this.changeset.save();

    this.args.afterSave();
  });
}
