import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.sortedEpics.length}}\n  <div data-test-epics-list>\n    <DragSortList\n      @items={{this.sortedEpics}}\n      draggingEnabled={{this.dragEnd.isIdle}}\n      @dragEndAction={{perform this.dragEnd}} as |epic|\n    >\n      {{yield epic}}\n    </DragSortList>\n  </div>\n{{/if}}", {"contents":"{{#if this.sortedEpics.length}}\n  <div data-test-epics-list>\n    <DragSortList\n      @items={{this.sortedEpics}}\n      draggingEnabled={{this.dragEnd.isIdle}}\n      @dragEndAction={{perform this.dragEnd}} as |epic|\n    >\n      {{yield epic}}\n    </DragSortList>\n  </div>\n{{/if}}","moduleName":"kancast-ui/components/epics/sortable-list.hbs","parseOptions":{"srcName":"kancast-ui/components/epics/sortable-list.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, restartableTask } from 'ember-concurrency';
import EpicModel from 'kancast-ui/models/epic';
import ProjectModel from 'kancast-ui/models/project';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';
import { prop } from 'ramda';
import { saveModel } from 'kancast-ui/utils/ember-data';

interface Args {
  project: ProjectModel;
  status: string;
}

export default class extends Component<Args> {
  @service
  store!: emberData__store;

  @tracked
  epicToEdit?: EpicModel;

  maxSortOrder = 100;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.fetchEpics.perform();
  }

  fetchEpics = restartableTask(async () => {
    await this.store.query('epic', {
      filter: {
        project_id: this.args.project.id,
        status: this.args.status,
        tenant_id: this.args.project.tenant.id,
      },
      include: 'reporter,tickets',
      sort: '-sort_order',
    });
  });

  get sortedEpics() {
    return this.store
      .peekAll('epic')
      .filter(
        (epic: EpicModel) =>
          epic.status === this.args.status &&
          epic.project === this.args.project &&
          !epic.isNew
      )
      .sortBy('sortOrder')
      .reverse();
  }

  dragEnd = dropTask(
    async (opts: {
      sourceList: EpicModel[];
      sourceIndex: number;
      targetList: EpicModel[];
      targetIndex: number;
    }) => {
      if (opts.sourceIndex === opts.targetIndex) return;

      const sourceList = opts.sourceList.toArray();

      const epic = sourceList[opts.sourceIndex];

      const newSortOrder = this.getNewSortOrder(
        opts.sourceIndex,
        opts.targetIndex,
        sourceList
      );

      if (newSortOrder !== epic.sortOrder) {
        epic.sortOrder = newSortOrder;
        await saveModel(epic);
      }
    }
  );

  private getNewSortOrder(
    sourceIndex: number,
    targetIndex: number,
    list: EpicModel[]
  ) {
    const sortOrders = list.map(prop('sortOrder'));
    if (targetIndex === 0) return Math.max(...sortOrders) + 100;

    if (targetIndex === list.length - 1) {
      return Math.min(...sortOrders) / 2;
    }

    return this.calcSortOrderBetweenTwoTickets(
      list[targetIndex],
      list[targetIndex + (targetIndex > sourceIndex ? 1 : -1)]
    );
  }

  private calcSortOrderBetweenTwoTickets(
    ticketA?: EpicModel,
    ticketB?: EpicModel
  ) {
    return ((ticketA?.sortOrder || 0) + (ticketB?.sortOrder || 0)) / 2;
  }
}
