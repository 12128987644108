import Model, { attr, belongsTo } from '@ember-data/model';
import ProjectModel from './project';
import UserModel from './user';

export default class ProjectMembershipModel extends Model {
  @attr('string', { defaultValue: 'member' })
  role!: string;

  @belongsTo('project', { async: false })
  project!: ProjectModel;

  @belongsTo('user', { async: false })
  user!: UserModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-membership': ProjectMembershipModel;
  }
}
