import Route from '@ember/routing/route';
import TenantModel from 'kancast-ui/models/tenant';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';

export default class IndexRoute extends Route {
  @service
  store!: emberData__store;

  async model() {
    const tenant: TenantModel = this.modelFor('tenant');
    const projects = await this.store.query('project', {
      filter: { tenant_id: tenant.id },
    });
    return { tenant, projects };
  }
}
