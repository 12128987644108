import Model, {
  attr,
  belongsTo,
  hasMany,
  SyncHasMany,
} from '@ember-data/model';
import { modelAction } from 'ember-custom-actions';
import ProjectModel from './project';
import TicketModel from './ticket';
import UserModel from './user';

export default class EpicModel extends Model {
  @attr('string')
  title!: string;

  @attr('string', { defaultValue: '' })
  description!: string;

  @attr('number')
  sortOrder!: number;

  @attr('string')
  labelColor!: string;

  @attr('string')
  scopedIdentifier!: string;

  @attr('string')
  status!: string;

  @attr('date')
  startedAt?: Date;

  @attr('date')
  finishedAt?: Date;

  @attr('date')
  insertedAt?: Date;

  @attr('date')
  originalFinishDateEstimation?: Date;

  @belongsTo('project', { async: false })
  project!: ProjectModel;

  @hasMany('ticket', { async: false })
  tickets!: SyncHasMany<TicketModel>;

  @belongsTo('user', { async: false })
  reporter!: UserModel;

  get isFinished() {
    return this.status === 'finished';
  }

  get isPlanning() {
    return this.status === 'planning';
  }

  get persistedTickets() {
    return this.tickets.rejectBy('isNew');
  }

  get finishedTicketsCount() {
    return this.persistedTickets.filter((t) => t.isFinished).length;
  }

  get unfinishedTicketsCount() {
    return this.persistedTickets.filter((t) => !t.isFinished).length;
  }

  get finishedTicketsPercent() {
    return (this.finishedTicketsCount / this.persistedTickets.length) * 100;
  }

  get areAllTicketsFinished() {
    return (
      this.finishedTicketsCount > 0 &&
      this.finishedTicketsCount === this.persistedTickets.length
    );
  }

  get hasOpenTickets() {
    return this.persistedTickets.length > 0 && !this.areAllTicketsFinished;
  }

  start = modelAction<() => Promise<unknown>>('start', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });

  finish = modelAction<() => Promise<unknown>>('finish', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });

  reopen = modelAction<() => Promise<unknown>>('reopen', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    epic: EpicModel;
  }
}
