import emberData__store from '@ember-data/store';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import InviteModel from 'kancast-ui/models/invite';
import CurrentUserService from 'kancast-ui/services/current-user';
import { SessionService } from 'services/session';

export default class extends Route {
  @service
  session!: SessionService;

  @service
  currentUser!: CurrentUserService;

  @service
  store!: emberData__store;

  beforeModel() {
    return this.currentUser.loadCurrentUser.lastRunning;
  }

  model(params: Record<'invite_id', string>) {
    return this.store.findRecord('invite', params.invite_id);
  }

  afterModel(resolvedModel: InviteModel, transition: Transition<unknown>) {
    if (!resolvedModel.userExists) return;

    if (!this.session.isAuthenticated)
      return this.session.requireAuthentication(transition, 'login');

    if (this.currentUser.user?.email !== resolvedModel.email)
      this.transitionTo('401');
  }
}
