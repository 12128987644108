import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import EpicModel from 'kancast-ui/models/epic';
import UserModel from 'kancast-ui/models/user';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';
import TicketModel from 'kancast-ui/models/ticket';
import { dropTask } from 'ember-concurrency';
import { NotificationsService } from 'services/notifications';
import ProjectModel from 'kancast-ui/models/project';

interface Filter {
  epics: EpicModel[];
  users: UserModel[];
  projects: ProjectModel[];
}

export default class extends Controller {
  @service
  store!: emberData__store;

  @service
  notifications!: NotificationsService;

  queryParams = ['page', 'sort', 'assigneeIds', 'epicIds', 'projectId'];

  @tracked
  sort = '-updated_at';

  @tracked
  page = 1;

  @tracked
  assigneeIds: string[] = [];

  @tracked
  epicIds: string[] = [];

  @tracked
  projectId?: string;

  @tracked
  ticketToShow?: TicketModel;

  get filter() {
    return {
      epics: this.epicIds.map((id) => this.store.peekRecord('epic', id)),
      users: this.assigneeIds.map((id) => this.store.peekRecord('user', id)),
      projects: this.projectId
        ? [this.store.peekRecord('project', this.projectId)]
        : [],
    };
  }

  @action
  showTicketDetails(ticket: TicketModel, event: Event) {
    this.ticketToShow = ticket;
    event.preventDefault();
  }

  @action
  hideTicketDetails() {
    this.ticketToShow = undefined;
  }

  @action
  setFilter(filter: Filter) {
    this.assigneeIds = filter.users.map((m) => m.id);
    this.epicIds = filter.epics.map((e) => e.id);
    this.projectId = filter.projects[0]?.id;
  }

  deleteTicket = dropTask(async () => {
    if (!this.ticketToShow) return;

    await this.ticketToShow?.destroyRecord();

    this.ticketToShow = undefined;

    this.notifications.success('The ticket has been removed');
  });
}
