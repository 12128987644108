import Model, {
  attr,
  hasMany,
  belongsTo,
  SyncHasMany,
} from '@ember-data/model';
import { modelAction } from 'ember-custom-actions';
import GithubIntegrationModel from './github-integration';
import TagModel from './tag';
import TenantModel from './tenant';
import EpicModel from './epic';
import ProjectMembershipModel from './project-membership';
export interface CustomField {
  name: string;
  content_type: string;
}

export interface TicketBucket {
  name: string;
  capacity: number;
  position: number;
}

export interface WorkflowItem {
  from_status: string;
  to_status: string;
}

export default class ProjectModel extends Model {
  @attr('string')
  name!: string;

  @attr('string')
  key!: string;

  @attr('string')
  description!: string;

  @attr('date')
  archivedAt?: Date | null;

  @attr
  customFields!: CustomField[];

  @attr
  ticketBuckets!: TicketBucket[];

  @attr()
  estimationCriteria!: string[];

  @attr
  workflow!: WorkflowItem[];

  @hasMany('epics', { async: false })
  epics!: SyncHasMany<EpicModel>;

  @hasMany('tag', { async: false })
  tags!: SyncHasMany<TagModel>;

  @hasMany('github-integration', { async: false })
  githubIntegrations!: SyncHasMany<GithubIntegrationModel>;

  @hasMany('project-membership', { async: false })
  members!: SyncHasMany<ProjectMembershipModel>;

  @belongsTo('tenant', { async: false })
  tenant!: TenantModel;

  archive = modelAction<() => Promise<unknown>>('archive', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    project: ProjectModel;
  }
}
