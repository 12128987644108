import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<th data-test-sortable-header role=\"button\" {{on \"click\" this.onClick}}>\n  {{yield}}\n\n  {{#if this.icon}}\n    <FaIcon data-test-sort-icon @icon={{this.icon}} />\n  {{/if}}\n</th>", {"contents":"<th data-test-sortable-header role=\"button\" {{on \"click\" this.onClick}}>\n  {{yield}}\n\n  {{#if this.icon}}\n    <FaIcon data-test-sort-icon @icon={{this.icon}} />\n  {{/if}}\n</th>","moduleName":"kancast-ui/components/sortable-header.hbs","parseOptions":{"srcName":"kancast-ui/components/sortable-header.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { match, isEmpty, complement, pipe, replace } from 'ramda';

const isAcs = (field: string) => isMatchRegExp(new RegExp(field));
const isDesc = (field: string) => isMatchRegExp(new RegExp(`-${field}`));
export const isMatchRegExp = (regexp: RegExp) =>
  pipe(match(regexp), complement(isEmpty));

interface Args {
  key: string;
  sortString: string;
  onSortStringChanged: (sortString: string) => unknown;
}

export default class extends Component<Args> {
  get icon() {
    if (this.isDesc) return 'arrow-down';
    if (this.isAcs) return 'arrow-up';
    return undefined;
  }

  get isAcs() {
    return isAcs(this.args.key)(this.args.sortString);
  }

  get isDesc() {
    return isDesc(this.args.key)(this.args.sortString);
  }

  @action
  onClick() {
    this.args.onSortStringChanged(this.buildNewSortString());
  }

  private buildNewSortString() {
    const { key, sortString } = this.args;
    if (this.isDesc) return replace(new RegExp(`-${key}`), '', sortString);
    if (this.isAcs) return replace(new RegExp(key), `-${key}`, sortString);
    if (isEmpty(sortString)) return key;
    return `${sortString},${key}`;
  }
}
