import Model, { attr, belongsTo } from '@ember-data/model';
import ProjectModel from './project';

export default class GithubIntegrationModel extends Model {
  @attr('string')
  name!: string;

  @attr('string')
  token!: string;

  @attr('string')
  webHookUrl!: string;

  @attr('boolean')
  activated!: string;

  @belongsTo('project')
  project!: ProjectModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'github-integration': GithubIntegrationModel;
  }
}
