import OAuth2PasswordGrant from 'ember-simple-auth/authenticators/oauth2-password-grant';
import { run } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import ENV from '../config/environment';

export default class GithubAuthenticator extends OAuth2PasswordGrant {
  authenticate(code, provider) {
    return new Promise((resolve, reject) => {
      const data = { code };
      const serverTokenEndpoint = `${ENV.serverURL}/api/oauth/${provider}`;

      this.makeRequest(serverTokenEndpoint, data, {}).then(
        (response) => {
          run(() => {
            if (!this._validate(response)) {
              reject('access_token is missing in server response');
            }

            const expiresAt = this._absolutizeExpirationTime(
              response['expires_in']
            );
            this._scheduleAccessTokenRefresh(
              response['expires_in'],
              expiresAt,
              response['refresh_token']
            );
            if (!isEmpty(expiresAt)) {
              response = Object.assign({}, response, { expires_at: expiresAt });
            }

            resolve(response);
          });
        },
        (response) => {
          run(null, reject, response);
        }
      );
    });
  }

  async makeRequest(url, data, headers = {}) {
    headers['Content-Type'] = 'application/vnd.api+json';

    const options = {
      body: JSON.stringify(data),
      headers,
      method: 'POST',
    };

    const response = await fetch(url, options);
    const json = await response.json();

    if (!response.ok) throw new Error('Bad response from API');

    return json;
  }
}
