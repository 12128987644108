import EmberRouter from '@ember/routing/router';
import config from 'kancast-ui/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');
  this.route('invite', { path: 'invites/:invite_id' });
  this.route('profile');
  this.route('new-tenant', { path: 'new' });
  this.route('tenant', { path: ':tenant_id' }, function () {
    this.route('new-project');
    this.route('project', { path: 'projects/:project_id' }, function () {
      this.route('edit');
      this.route('stats');
      this.route('board', function () {
        this.route('ticket', { path: 'tickets/:ticket_id' }, function () {
          this.route('edit');
        });
      });
      this.route('settings', function () {
        this.route('members');
        this.route('integrations', function () {
          this.route('new');
          this.route('github-integration', {
            path: 'integrations/github/:github_integration_id',
          });
        });
      });
      this.route('epics', function () {
        this.route('in-progress');
        this.route('finished');
        this.route('epic', { path: ':epic_id' }, function () {
          this.route('edit');
        });
      });
    });
    this.route('settings', function () {
      this.route('users');
      this.route('invites', function () {
        this.route('new');
      });
    });
    this.route('tickets');
    this.route('401');
  });
  this.route('401');
  this.route('500');

  this.route('reset-password-request');
  this.route('reset-password-request-sent');
  this.route('reset-password', {
    path: 'reset-password/:reset_password_token_id',
  });

  this.route('sign-up');
  this.route('oauth', function () {
    this.route('github');
  });
});
