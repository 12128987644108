import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { AbilitiesService } from 'services/abilities';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service
  abilities!: AbilitiesService;

  beforeModel(transition: Transition) {
    const project = this.modelFor('tenant.project');
    if (this.abilities.cannot('manage project', project)) {
      transition.abort();
      this.transitionTo('tenant.401');
    }
  }
}
