import emberData__store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ProjectModel from 'kancast-ui/models/project';

export default class extends Route {
  @service
  store!: emberData__store;

  model() {
    const project: ProjectModel = this.modelFor('tenant.project');

    return this.store.createRecord('github-integration', {
      project,
      name: `${project.name} | GitHub Integration`,
    });
  }
}
