import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Filters::Container\n  @filter={{@filter}}\n  @setFilter={{@setFilter}} as |toggleFilter|\n>\n  <Filters::FilterByProjects\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @projects={{this.projects}}\n  />\n  <Filters::FilterByMembers\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @members={{this.members}}\n    @hideUnassigned={{true}}\n  />\n  <Filters::FilterByEpics\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @epics={{this.epics}}\n  />\n</Filters::Container>", {"contents":"<Filters::Container\n  @filter={{@filter}}\n  @setFilter={{@setFilter}} as |toggleFilter|\n>\n  <Filters::FilterByProjects\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @projects={{this.projects}}\n  />\n  <Filters::FilterByMembers\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @members={{this.members}}\n    @hideUnassigned={{true}}\n  />\n  <Filters::FilterByEpics\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @epics={{this.epics}}\n  />\n</Filters::Container>","moduleName":"kancast-ui/components/tenant/tickets-filters.hbs","parseOptions":{"srcName":"kancast-ui/components/tenant/tickets-filters.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import ArrayProxy from '@ember/array/proxy';
import UserModel from 'kancast-ui/models/user';
import EpicModel from 'kancast-ui/models/epic';
import CurrentUserService from 'kancast-ui/services/current-user';
import ProjectModel from 'kancast-ui/models/project';

export default class extends Component {
  @service
  store!: emberData__store;

  @service
  currentUser!: CurrentUserService;

  @tracked
  members: ArrayProxy<UserModel>;

  @tracked
  epics: ArrayProxy<EpicModel>;

  @tracked
  projects: ArrayProxy<ProjectModel>;

  constructor(owner: unknown, args: {}) {
    super(owner, args);
    this.members = this.store.query('user', {
      tenant_id: this.currentUser.currentTenant?.id,
    });
    this.epics = this.store.query('epic', {
      filter: { tenant_id: this.currentUser.currentTenant?.id },
      include: 'project',
    });
    this.projects = this.store.query('project', {
      filter: { tenant_id: this.currentUser.currentTenant?.id },
    });
  }
}
