import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"input-group\" ...attributes>\n  <div class=\"input-group-prepend\">\n    <BsButton\n      data-test-copy-btn\n      @type=\"secondary\"\n      @outline={{true}}\n      @onClick={{perform this.copyToClipboard}}\n    >\n      {{this.buttonText}}\n    </BsButton>\n  </div>\n  {{! template-lint-disable require-input-label }}\n  <input\n    type=\"text\"\n    class=\"form-control\"\n    readonly={{@readonly}}\n    value={{@value}}\n    placeholder={{@placeholder}}\n  />\n</div>", {"contents":"<div class=\"input-group\" ...attributes>\n  <div class=\"input-group-prepend\">\n    <BsButton\n      data-test-copy-btn\n      @type=\"secondary\"\n      @outline={{true}}\n      @onClick={{perform this.copyToClipboard}}\n    >\n      {{this.buttonText}}\n    </BsButton>\n  </div>\n  {{! template-lint-disable require-input-label }}\n  <input\n    type=\"text\"\n    class=\"form-control\"\n    readonly={{@readonly}}\n    value={{@value}}\n    placeholder={{@placeholder}}\n  />\n</div>","moduleName":"kancast-ui/components/input-with-copy-btn.hbs","parseOptions":{"srcName":"kancast-ui/components/input-with-copy-btn.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import ENV from '../config/environment';

interface Args {
  value: string;
}

export default class extends Component<Args> {
  @tracked
  buttonText = 'Copy';

  copyToClipboard = restartableTask(async () => {
    await navigator.clipboard.writeText(this.args.value);
    this.buttonText = 'Copied!';
    if (ENV.environment === 'test') return;
    await timeout(5_000);
    this.buttonText = 'Copy';
  });
}
