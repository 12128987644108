import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import ProjectMembershipModel from 'kancast-ui/models/project-membership';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';
import { action } from '@ember/object';
import ProjectModel from 'kancast-ui/models/project';
import { dropTask } from 'ember-concurrency';
import { NotificationsService } from 'services/notifications';
import { BufferedChangeset } from 'ember-changeset/types';
import { validatePresence } from 'ember-changeset-validations/validators';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { saveModel } from 'kancast-ui/utils/ember-data';

const Validations = {
  user: validatePresence(true),
  role: validatePresence(true),
};

export default class extends Controller {
  model!: ProjectModel;

  @service
  store!: emberData__store;

  @service
  notifications!: NotificationsService;

  @tracked
  newMember?: BufferedChangeset;

  get availableUsers() {
    const members = this.model.members.map((m) => m.user);
    return this.model.tenant.tenantMemberships.reject((m) =>
      members.includes(m.user)
    );
  }

  submitNewMember = dropTask(async () => {
    if (!this.newMember) return;

    await this.newMember.validate();

    if (this.newMember.isInvalid) return;

    await this.newMember.save();

    this.hideAddMemberForm();

    this.notifications.success(
      'New member has been added to the project successfully'
    );
  });

  changeRole = dropTask(
    async (member: ProjectMembershipModel, newRole: string) => {
      member.role = newRole;
      await saveModel(member);
    }
  );

  removeMember = dropTask(async (member: ProjectMembershipModel) => {
    await member.destroyRecord();
  });

  @action
  showAddMemberForm() {
    this.newMember = Changeset(
      this.store.createRecord('project-membership', {
        project: this.model,
      }),
      lookupValidator(Validations),
      Validations
    );
  }

  @action
  cancelAddingMember() {
    if (!this.newMember) return;
    (this.newMember.data as ProjectMembershipModel).deleteRecord();
    this.hideAddMemberForm();
  }

  private hideAddMemberForm() {
    this.newMember = undefined;
  }
}
