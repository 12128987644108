import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsForm\n  data-test-ticket-form\n  @formLayout=\"vertical\"\n  @model={{this.changeset}}\n  @onSubmit={{perform this.submit}} as |form|\n>\n  <form.element\n    data-test-user-first-name\n    @controlType=\"text\"\n    @label=\"First Name\"\n    @property=\"firstName\"\n  />\n  <form.element\n    data-test-user-last-name\n    @controlType=\"text\"\n    @label=\"Last Name\"\n    @property=\"lastName\"\n  />\n\n  <form.submitButton data-test-submit-profile>\n    Save\n  </form.submitButton>\n</BsForm>", {"contents":"<BsForm\n  data-test-ticket-form\n  @formLayout=\"vertical\"\n  @model={{this.changeset}}\n  @onSubmit={{perform this.submit}} as |form|\n>\n  <form.element\n    data-test-user-first-name\n    @controlType=\"text\"\n    @label=\"First Name\"\n    @property=\"firstName\"\n  />\n  <form.element\n    data-test-user-last-name\n    @controlType=\"text\"\n    @label=\"Last Name\"\n    @property=\"lastName\"\n  />\n\n  <form.submitButton data-test-submit-profile>\n    Save\n  </form.submitButton>\n</BsForm>","moduleName":"kancast-ui/components/profile-form.hbs","parseOptions":{"srcName":"kancast-ui/components/profile-form.hbs"}});
import Component from '@glimmer/component';
import UserModel from 'kancast-ui/models/user';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { NotificationsService } from 'services/notifications';

interface Args {
  user: UserModel;
}

const Validations = {};

export default class extends Component<Args> {
  @service
  notifications!: NotificationsService;

  @tracked
  changeset: BufferedChangeset;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.changeset = Changeset(
      args.user,
      lookupValidator({ Validations }),
      Validations
    );
  }

  submit = dropTask(async () => {
    await this.changeset.validate();

    if (this.changeset.isValid) {
      try {
        await this.changeset.save();
        this.notifications.success('Your profile has been saved successfully');
      } catch (e) {
        this.notifications.error('Something went wrong');
        throw e;
      }
    }
  });
}
