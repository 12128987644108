import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BasicDropdown as |dd|>\n  <dd.Trigger\n    data-test-filters\n    local-class=\"trigger\"\n    class=\"btn btn-outline-primary\"\n  >\n    <FaIcon @icon=\"filter\" />\n    Filters\n  </dd.Trigger>\n  <dd.Content\n    class=\"shadow p-3 mt-2 bg-white rounded\"\n    local-class=\"dropdown-container\"\n  >\n    {{yield this.toggleFilter}}\n  </dd.Content>\n</BasicDropdown>", {"contents":"<BasicDropdown as |dd|>\n  <dd.Trigger\n    data-test-filters\n    local-class=\"trigger\"\n    class=\"btn btn-outline-primary\"\n  >\n    <FaIcon @icon=\"filter\" />\n    Filters\n  </dd.Trigger>\n  <dd.Content\n    class=\"shadow p-3 mt-2 bg-white rounded\"\n    local-class=\"dropdown-container\"\n  >\n    {{yield this.toggleFilter}}\n  </dd.Content>\n</BasicDropdown>","moduleName":"kancast-ui/components/filters/container.hbs","parseOptions":{"srcName":"kancast-ui/components/filters/container.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { without } from 'ramda';

type Filter = Record<string, unknown[]>;

enum FilterMode {
  REPLACE = 'replace',
}

interface Args {
  filter: Filter;
  setFilter: (filter: Filter) => unknown;
}

export default class extends Component<Args> {
  @action
  toggleFilter(filterKey: keyof Filter, value: any, mode?: FilterMode) {
    const { filter, setFilter } = this.args;
    if (this.args.filter[filterKey].includes(value)) {
      setFilter({
        ...filter,
        [filterKey]: without([value], filter[filterKey]),
      });
    } else {
      const newFilterValue =
        mode === FilterMode.REPLACE ? [value] : [...filter[filterKey], value];
      setFilter({
        ...filter,
        [filterKey]: newFilterValue,
      });
    }
  }
}
