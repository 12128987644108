import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ApplicationRoute extends Route {
  @service currentUser;

  async beforeModel() {
    document.getElementById('initial-loading')?.remove();
    await this.currentUser.loadCurrentUser.perform();
  }
}
