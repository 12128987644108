import Controller from '@ember/controller';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import TicketModel from 'kancast-ui/models/ticket';

export default class TicketIndexController extends Controller {
  model!: TicketModel;

  @action
  onHidden() {
    this.transitionToRoute('tenant.project.index');
  }

  deleteTicket = dropTask(async () => {
    await this.model.destroyRecord();
    this.transitionToRoute('tenant.project.index');
  });
}
