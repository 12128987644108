import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<form class=\"form\" novalidate {{on \"submit\" this.doNothing}}>\n  <div class=\"input-group mb-3\">\n    {{! template-lint-disable require-input-label }}\n    <input\n      data-test-estimation-name\n      type=\"text\"\n      class=\"form-control\"\n      placeholder=\"New Estimation Criterion\"\n      value={{this.value}}\n      {{on \"input\" (pick \"target.value\" (fn (mut this.value)))}}\n      {{on \"keyup\" this.onKeyPress}}\n    />\n    <BsButton\n      data-test-add-estimation\n      @outline={{true}}\n      @onClick={{this.onSubmit}}\n    >\n      Add\n    </BsButton>\n  </div>\n</form>", {"contents":"<form class=\"form\" novalidate {{on \"submit\" this.doNothing}}>\n  <div class=\"input-group mb-3\">\n    {{! template-lint-disable require-input-label }}\n    <input\n      data-test-estimation-name\n      type=\"text\"\n      class=\"form-control\"\n      placeholder=\"New Estimation Criterion\"\n      value={{this.value}}\n      {{on \"input\" (pick \"target.value\" (fn (mut this.value)))}}\n      {{on \"keyup\" this.onKeyPress}}\n    />\n    <BsButton\n      data-test-add-estimation\n      @outline={{true}}\n      @onClick={{this.onSubmit}}\n    >\n      Add\n    </BsButton>\n  </div>\n</form>","moduleName":"kancast-ui/components/project/estimation-criterion-form.hbs","parseOptions":{"srcName":"kancast-ui/components/project/estimation-criterion-form.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {
  onSubmit(value: string): unknown;
}

export default class extends Component<Args> {
  @tracked
  value = '';

  @action
  onSubmit() {
    if (this.value) {
      this.args.onSubmit(this.value);
      this.value = '';
    }
  }

  @action
  onKeyPress(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.key === 'Enter' && this.value) {
      this.onSubmit();
    }
  }

  @action
  doNothing(event: Event) {
    event.preventDefault();
  }
}
