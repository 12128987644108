import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { Router } from '@ember/routing';
import { SessionService } from 'services/session';
import CurrentUserService from 'kancast-ui/services/current-user';
import { NotificationsService } from 'services/notifications';
import { dropTask } from 'ember-concurrency';

export default class LoginController extends Controller {
  password = '';
  email = '';

  @service
  router!: Router;

  @service
  notifications!: NotificationsService;

  @service
  session!: SessionService;

  @service
  currentUser!: CurrentUserService;

  login = dropTask(async () => {
    try {
      await this.session.authenticate(
        'authenticator:oauth2',
        this.email,
        this.password
      );
    } catch (e) {
      this.notifications.error('Username or Password is not correct');
      throw e;
    }
  });
}
