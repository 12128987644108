import { inject as service } from '@ember/service';
import { Ability } from 'ember-can';
import TenantModel from 'kancast-ui/models/tenant';
import CurrentUserService from 'kancast-ui/services/current-user';

export default class TenantAbility extends Ability {
  @service
  currentUser!: CurrentUserService;

  model!: TenantModel;

  get canManage() {
    return (
      this.currentUser.user?.tenantMemberships.find(
        (m) => m.belongsTo('tenant').id() === this.model?.id
      )?.role === 'admin'
    );
  }
}
