import emberData__store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service
  store!: emberData__store;

  model(params: { ticket_id: string }) {
    return this.store.findRecord('ticket', params.ticket_id, {
      include: 'tags,comments,merge_requests,epic',
      reload: true,
    });
  }
}
