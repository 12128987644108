import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"container\" ...attributes>\n  {{#each @tags as |tag|}}\n    <Tag::Badge\n      @tag={{tag}}\n      @actionable={{@allowAdd}}\n      {{on \"click\" this.onTagClick}}\n    />\n  {{/each}}\n  {{#if @allowAdd}}\n    <Tag::TagsPicker\n      @changeset={{@ticketChangeset}}\n      @onChange={{@onChange}}\n      @registerDropdownActions={{this.registerTagPickerActions}}\n    />\n  {{/if}}\n</div>", {"contents":"<div local-class=\"container\" ...attributes>\n  {{#each @tags as |tag|}}\n    <Tag::Badge\n      @tag={{tag}}\n      @actionable={{@allowAdd}}\n      {{on \"click\" this.onTagClick}}\n    />\n  {{/each}}\n  {{#if @allowAdd}}\n    <Tag::TagsPicker\n      @changeset={{@ticketChangeset}}\n      @onChange={{@onChange}}\n      @registerDropdownActions={{this.registerTagPickerActions}}\n    />\n  {{/if}}\n</div>","moduleName":"kancast-ui/components/tag/list.hbs","parseOptions":{"srcName":"kancast-ui/components/tag/list.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { DropdownActions } from 'ember-basic-dropdown/addon/components/basic-dropdown';

export default class extends Component {
  tagPickerActions?: DropdownActions;

  @action
  registerTagPickerActions(actions: DropdownActions) {
    this.tagPickerActions = actions;
  }

  @action
  onTagClick() {
    if (!this.tagPickerActions) return;
    this.tagPickerActions.open();
  }
}
