import { getOwner } from '@ember/application';
import HttpService from 'kancast-ui/services/http';

export interface BackendValidationOptions<V, C> {
  message(value: V): string;
  buildUrl(value: V, content: C): string;
}

export const backendValidation =
  <V, C>(opts: BackendValidationOptions<V, C>) =>
  async (
    _key: string,
    value: V,
    _oldValue: V,
    _changes: Partial<C>,
    content: C
  ) => {
    const httpService: HttpService = getOwner(content).lookup('service:http');
    const response = await httpService.fetch(opts.buildUrl(value, content));
    const json = await response.json();

    return json.result ? true : opts.message(value);
  };
