import ArrayProxy from '@ember/array/proxy';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import EpicModel from 'kancast-ui/models/epic';
import ProjectModel from 'kancast-ui/models/project';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';
import { Color } from 'kancast-ui/constants/color';

export default class EpicsController extends Controller {
  @service
  store!: emberData__store;

  @tracked
  epics?: ArrayProxy<EpicModel> & { meta?: { total?: number } };

  model!: ProjectModel;

  @tracked
  epicToEdit?: EpicModel;

  get maxSortOrder() {
    return Math.max(
      Math.max(
        ...this.store
          .peekAll('epic')
          .filterBy('status', 'planning')
          .map((epic: EpicModel) => epic.sortOrder)
      ),
      100
    );
  }

  @action
  addNewEpic() {
    this.epicToEdit = this.store.createRecord('epic', {
      project: this.model,
      sortOrder: this.maxSortOrder + 100,
      labelColor: Color.GREY,
      status: 'planning',
    });
  }

  @action
  cancelEditing() {
    this.epicToEdit = undefined;
  }

  @action
  afterSaveEpic() {
    this.cancelEditing();
  }
}
