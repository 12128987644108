import Route from '@ember/routing/route';
import TenantModel from 'kancast-ui/models/tenant';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';

export default class InvitesRoute extends Route {
  @service
  store!: emberData__store;

  model() {
    const tenant: TenantModel = this.modelFor('tenant');
    return this.store.query('invite', { filter: { tenant_id: tenant.id } });
  }
}
