import Model, { attr, belongsTo } from '@ember-data/model';
import { modelAction } from 'ember-custom-actions';
import TenantModel from './tenant';

export default class InviteModel extends Model {
  @attr('string')
  email!: string;

  @attr('string')
  role!: string;

  @attr('boolean')
  userExists!: boolean;

  @attr('boolean')
  used!: boolean;

  @attr('date')
  insertedAt!: Date;

  @attr()
  projectMemberships!: unknown[];

  @belongsTo('tenant')
  tenant!: TenantModel;

  register = modelAction<
    (opts: Record<'password', string>) => Promise<unknown>
  >('register', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });
  approve = modelAction<() => Promise<unknown>>('approve', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    invite: InviteModel;
  }
}
