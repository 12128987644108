import emberData__store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ProjectRoute extends Route {
  @service
  store!: emberData__store;

  model(params: { project_id: string }) {
    return this.store.findRecord('project', params.project_id, {
      include: 'tags,members.user',
      reload: true,
    });
  }
}
