import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<HighCharts @chartOptions={{this.chartOptions}} @content={{this.charData}} />", {"contents":"<HighCharts @chartOptions={{this.chartOptions}} @content={{this.charData}} />","moduleName":"kancast-ui/components/ticket-predicted-timeline.hbs","parseOptions":{"srcName":"kancast-ui/components/ticket-predicted-timeline.hbs"}});
import Component from '@glimmer/component';
import { DateTime, Duration } from 'luxon';
import { WorkflowItem } from 'kancast-ui/models/project';
import { Prediction } from 'kancast-ui/models/ticket';
import {
  generateTicketTimeLine,
  Timeline,
} from 'kancast-ui/utils/generate-ticket-timeline';

const ONE_DAY = 24;

const getFormattedDuration = (duration: number, predicted: boolean) =>
  predicted
    ? Duration.fromObject({
        hours: Math.round(duration) ?? ONE_DAY,
      }).toFormat("d'd' h'h'")
    : '??';
interface SeriesData {
  x: number | Date;
  name: string;
  label: string;
  description: string;
}

interface Args {
  predictions: Prediction[];
  startTimestamp: Date;
  startStatus?: string;
  workflow: WorkflowItem[];
}

const getStartPoint = (
  startStatus: string | undefined,
  startTimestamp: Date
): SeriesData => {
  if (startStatus) {
    return {
      x: startTimestamp,
      name: startStatus,
      label: DateTime.fromJSDate(startTimestamp).toFormat('f'),
      description: 'Current ticket status',
    };
  } else {
    return {
      x: startTimestamp,
      name: 'Start point',
      label: DateTime.fromJSDate(startTimestamp).toFormat('f'),
      description: 'The ticket had been started',
    };
  }
};

const buildSeriesData = (timeline: Timeline): SeriesData => ({
  x: timeline.date.valueOf(),
  name: timeline.toStatus,
  label:
    timeline.date.toFormat('f') +
    (timeline.predicted ? '' : ' (not predicted)'),
  description: `The transition from "${timeline.fromStatus}" to "${
    timeline.toStatus
  }" will take: ${getFormattedDuration(timeline.duration, timeline.predicted)}`,
});

export default class TicketPredictedTimelineComponent extends Component<Args> {
  get seriesData() {
    const { predictions, startStatus, startTimestamp, workflow } = this.args;
    const startDateTime = startTimestamp
      ? DateTime.fromJSDate(startTimestamp)
      : undefined;
    const data = generateTicketTimeLine(
      predictions,
      workflow,
      startDateTime,
      startStatus
    ).map(buildSeriesData);
    return [getStartPoint(startStatus, startTimestamp), ...data];
  }

  chartOptions = {
    chart: {
      type: 'timeline',
    },
    xAxis: {
      type: 'datetime',
      visible: false,
    },
    yAxis: {
      visible: false,
      gridLineWidth: 1,
      title: null,
      labels: {
        enabled: false,
      },
    },
    title: {
      text: null,
    },
  };

  get charData() {
    return [
      {
        dataLabels: {
          connectorColor: 'silver',
          connectorWidth: 2,
        },
        marker: {
          symbol: 'circle',
        },
        data: this.seriesData,
      },
    ];
  }
}
