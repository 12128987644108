import Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { SessionService } from 'services/session';
import { dropTask } from 'ember-concurrency';
import UserModel from 'kancast-ui/models/user';
import { tracked } from '@glimmer/tracking';
import TenantModel from 'kancast-ui/models/tenant';

export default class CurrentUserService extends Service {
  @service
  session!: SessionService;

  @service
  store!: Store;

  @tracked
  currentTenant?: TenantModel;

  user: UserModel | null = null;

  loadCurrentUser = dropTask(async () => {
    if (!this.session.isAuthenticated) return;
    try {
      const user: UserModel = await this.store.findRecord(
        'user',
        this.session.data.authenticated.user_id,
        { include: 'tenant_memberships' }
      );
      this.user = user;
    } catch (_e) {
      console.error(_e);
      //this.session.invalidate();
    }
  });
}
