import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.client}}\n  <BsButton\n    data-test-sign-in-with-google\n    class=\"btn-block\"\n    @type=\"primary\"\n    @outline={{true}}\n    @onClick={{this.requestCode}}\n    disabled={{this.handleCodeResponse.isRunning}}\n    ...attributes\n  >\n    {{#if this.handleCodeResponse.isRunning}}\n      <FaIcon @icon=\"cog\" @spin={{true}} />\n    {{/if}}\n    Sign in with Google\n  </BsButton>\n{{/if}}", {"contents":"{{#if this.client}}\n  <BsButton\n    data-test-sign-in-with-google\n    class=\"btn-block\"\n    @type=\"primary\"\n    @outline={{true}}\n    @onClick={{this.requestCode}}\n    disabled={{this.handleCodeResponse.isRunning}}\n    ...attributes\n  >\n    {{#if this.handleCodeResponse.isRunning}}\n      <FaIcon @icon=\"cog\" @spin={{true}} />\n    {{/if}}\n    Sign in with Google\n  </BsButton>\n{{/if}}","moduleName":"kancast-ui/components/oauth/google-sign-in-button.hbs","parseOptions":{"srcName":"kancast-ui/components/oauth/google-sign-in-button.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { SessionService } from 'services/session';
import { NotificationsService } from 'services/notifications';
import ENV from 'kancast-ui/config/environment';

declare global {
  var google: any;
}

interface GoogleCodeClient {
  requestCode: Function;
}

interface GoogleCodeResponse {
  code: string;
}

export default class extends Component {
  @service
  session!: SessionService;

  @service
  notifications!: NotificationsService;

  client?: GoogleCodeClient;

  constructor(owner: unknown, args: {}) {
    super(owner, args);

    this.initClient.perform();
  }

  @action
  requestCode() {
    if (!this.client) return;
    this.client.requestCode();
  }

  initClient = dropTask(async () => {
    while (ENV.environment !== 'test' && window.google === undefined) {
      await timeout(500);
    }
    if (!window.google) return;
    this.client = window.google.accounts.oauth2.initCodeClient({
      client_id: ENV.googleOAuthClientId,
      scope:
        'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      ux_mode: 'popup',
      callback: (res: GoogleCodeResponse) =>
        this.handleCodeResponse.perform(res),
    });
  });

  handleCodeResponse = restartableTask(async (res: GoogleCodeResponse) => {
    try {
      await this.session.authenticate('authenticator:code', res.code, 'google');
    } catch (e) {
      this.notifications.error('Something went wrong');
      throw e;
    }
  });
}
