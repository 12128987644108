import emberData__store from '@ember-data/store';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

interface Params {
  page: number;
  assigneeIds?: string[];
  epicIds?: string[];
  projectId?: string;
  sort?: string;
}

export default class extends Route {
  @service
  store!: emberData__store;

  queryParams = {
    page: {
      refreshModel: true,
    },
    assigneeIds: {
      refreshModel: true,
    },
    epicIds: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
    projectId: {
      refreshModel: true,
    },
  };

  model(params: Params) {
    return this.store.query('ticket', {
      filter: {
        assignee_ids: params.assigneeIds,
        epic_ids: params.epicIds,
        project_id: params.projectId,
        tenant_id: this.modelFor('tenant').id,
      },
      page: { number: params.page, size: 20 },
      sort: params.sort,
      include: [
        'assignee',
        'reporter',
        'epic',
        'tags',
        'project.members.user',
      ].join(','),
    });
  }

  async afterModel(params: Params) {
    const assigneeIds = params.assigneeIds || [];
    const epicIds = params.epicIds || [];

    const promises: Promise<unknown>[] = [
      ...assigneeIds.map(async (id) => this.store.findRecord('user', id)),
      ...epicIds.map((id) => this.store.findRecord('epic', id)),
      params.projectId
        ? this.store.findRecord('project', params.projectId)
        : Promise.resolve(),
    ];

    return Promise.all(promises);
  }

  @action
  loading() {
    return false;
  }
}
