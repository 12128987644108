import transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import FinishedEpicsController from 'kancast-ui/controllers/tenant/project/epics/finished';

export default class extends Route {
  setupController(
    controller: FinishedEpicsController,
    model: unknown,
    transition: transition<unknown>
  ) {
    super.setupController(controller, model, transition);
    controller.fetchEpics.perform();
  }
}
