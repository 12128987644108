import Model, { attr, belongsTo } from '@ember-data/model';
import ProjectModel from './project';

export default class TagModel extends Model {
  @attr('string')
  name!: string;

  @attr('string')
  color!: string;

  @belongsTo('project', { async: false })
  project!: ProjectModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tag: TagModel;
  }
}
