import Route from '@ember/routing/route';
import ProjectModel from 'kancast-ui/models/project';
import { isNil, reject } from 'ramda';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';

const compact = reject(isNil);

export default class extends Route {
  @service
  store!: emberData__store;

  async model() {
    const project: ProjectModel = this.modelFor('tenant.project');
    const stats = await this.store.query('project-prediction-stat', {
      filter: { project_id: project.id },
    });

    return {
      project,
      stats: compact(
        project.workflow.map(({ from_status, to_status }) =>
          stats.find(
            (s) => s.toStatus === to_status && s.fromStatus === from_status
          )
        )
      ),
    };
  }
}
