import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"badge {{this.badgeClass}}\">{{@ticketType}}</span>", {"contents":"<span class=\"badge {{this.badgeClass}}\">{{@ticketType}}</span>","moduleName":"kancast-ui/components/ticket-badge.hbs","parseOptions":{"srcName":"kancast-ui/components/ticket-badge.hbs"}});
import Component from '@glimmer/component';

const TICKET_TYPE_CSS_CLASS_MAPPER = {
  feature: 'bg-success',
  bug: 'bg-danger',
  chore: 'bg-secondary',
};

interface Args {
  ticketType: 'feature' | 'bug' | 'chore';
}

export default class TicketBageComponent extends Component<Args> {
  get badgeClass() {
    return TICKET_TYPE_CSS_CLASS_MAPPER[this.args.ticketType];
  }
}
