import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { modelAction } from 'ember-custom-actions';
import ProjectModel from './project';
import TicketStatusTimeLogModel from './ticket-status-time-log';
import EmberArray from '@ember/array';
import UserModel from './user';
import { tracked } from '@glimmer/tracking';
import TagModel from './tag';
import TicketCommentModel from './ticket-comment';
import ScmMergeRequestModel from './scm-merge-request';
import EpicModel from './epic';

export interface EstimationData {
  value: number;
  name: string;
}
export class Estimation implements EstimationData {
  @tracked
  value = 0;

  @tracked
  name = '';

  constructor(name: string, value: number) {
    this.name = name;
    this.value = value;
  }
}

export interface Prediction {
  from_status: string;
  to_status: string;
  avg_deviance: number;
  prediction: number;
}

export interface CustomField {
  name: string;
  content: string;
  content_type: string;
}

export default class TicketModel extends Model {
  @attr('string')
  title!: string;

  @attr('string')
  key!: string;

  @attr('string', { defaultValue: '' })
  description!: string;

  @attr('string')
  status!: string;

  @attr('string')
  scopedIdentifier!: string;

  @attr('string', { defaultValue: 'feature' })
  ticketType!: string;

  @attr('date')
  insertedAt!: Date;

  @attr('date')
  updatedAt!: Date;

  @attr('number')
  sortOrder!: number;

  @attr('date')
  statusChangedAt!: Date;

  @attr('date')
  startedAt!: Date;

  @attr
  predictions!: Prediction[];

  @attr
  customFields!: CustomField[];

  @attr('estimations')
  estimations!: Estimation[];

  @belongsTo('project', { async: false })
  project!: ProjectModel;

  @belongsTo('user', { async: false })
  reporter!: UserModel;

  @belongsTo('user', { async: false })
  assignee!: UserModel;

  @belongsTo('epic', { async: false })
  epic!: EpicModel;

  @hasMany('ticket-status-time-log', { async: false })
  ticketStatusTimeLogs!: EmberArray<TicketStatusTimeLogModel>;

  @hasMany('tag', { async: false })
  tags!: EmberArray<TagModel>;

  @hasMany('ticket-comment', { async: false })
  comments!: EmberArray<TicketCommentModel>;

  @hasMany('scm-merge-request', { async: false })
  mergeRequests!: EmberArray<ScmMergeRequestModel>;

  changeStatus = modelAction<
    (opts: Record<'newStatus', string>) => Promise<unknown>
  >('change-status', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });

  assign = modelAction<
    (opts: Record<'assigneeId', string | undefined>) => Promise<unknown>
  >('assign', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });

  changeEstimations = modelAction<
    (opts: Record<'estimations', EstimationData[]>) => Promise<unknown>
  >('change-estimations', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });

  recalculatePredictions = modelAction<() => Promise<unknown>>(
    'recalculate-predictions',
    {
      normalizeOperation: 'underscore',
      pushToStore: true,
    }
  );

  changeEpicId = modelAction<
    (opts: Record<'epicId', string | null>) => Promise<unknown>
  >('change-epic-id', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });

  get isFinished() {
    return this.project.workflow?.lastObject?.to_status === this.status;
  }

  get isFirstStep() {
    return this.project.workflow?.firstObject?.from_status === this.status;
  }

  get nextStatus() {
    return this.project.workflow?.find(
      (item) => item.from_status === this.status
    )?.to_status;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    ticket: TicketModel;
  }
}
