import Model, { attr, hasMany, SyncHasMany } from '@ember-data/model';
import TenantMembershipModel from './tenant-membership';

export default class TenantModel extends Model {
  @attr('string')
  name!: string;

  @hasMany('tenant-membership')
  tenantMemberships!: SyncHasMany<TenantMembershipModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tenant: TenantModel;
  }
}
