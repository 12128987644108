import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n  ...attributes\n  data-test-add-estimation\n  class=\"mb-2\"\n  @renderInPlace={{true}}\n  @options={{this.unusedCriterions}}\n  @onChange={{@addEstimation}}\n  @searchEnabled={{true}}\n  @placeholder=\"Add a new estimation criterion\"\n  @noMatchesMessage=\"Type and press enter to add a new element\"\n  @onKeydown={{this.createNewCriterionOnEnter}} as |criterion|\n>\n  {{criterion}}\n</PowerSelect>", {"contents":"<PowerSelect\n  ...attributes\n  data-test-add-estimation\n  class=\"mb-2\"\n  @renderInPlace={{true}}\n  @options={{this.unusedCriterions}}\n  @onChange={{@addEstimation}}\n  @searchEnabled={{true}}\n  @placeholder=\"Add a new estimation criterion\"\n  @noMatchesMessage=\"Type and press enter to add a new element\"\n  @onKeydown={{this.createNewCriterionOnEnter}} as |criterion|\n>\n  {{criterion}}\n</PowerSelect>","moduleName":"kancast-ui/components/ticket-form-new-estimation-control.hbs","parseOptions":{"srcName":"kancast-ui/components/ticket-form-new-estimation-control.hbs"}});
import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import EstimationCriterion from 'kancast-ui/models/estimation-criterion';
import { prop } from 'ramda';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';

type AddEstimationFn = (name: string) => unknown;

interface Args {
  changeset: BufferedChangeset;
  usedCriterions: string[];
  addEstimation: AddEstimationFn;
  projectId: string;
}

export default class extends Component<Args> {
  @service store!: Store;

  criterions: DS.PromiseArray<EstimationCriterion>;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.criterions = this.store.query('estimation-criterion', {
      filter: {
        project_id: args.projectId,
      },
    });
  }

  get unusedCriterions() {
    return this.criterions
      .filter((c) => !this.args.usedCriterions.includes(c.name))
      .map(prop('name'));
  }

  @action
  createNewCriterionOnEnter(select: any, e: Record<'keyCode', number>) {
    if (
      e.keyCode === 13 &&
      select.isOpen &&
      !select.highlighted &&
      !isBlank(select.searchText)
    ) {
      this.args.addEstimation(select.searchText as string);
    }
  }
}
