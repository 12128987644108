import Model, { attr, hasMany } from '@ember-data/model';
import TenantMembershipModel from './tenant-membership';
import EmberArray from '@ember/array';
import { isEmpty, isNil, join, pipe, reject, anyPass, map, head } from 'ramda';
import { modelAction } from 'ember-custom-actions';

const rejectNilOrEmpty = reject(anyPass([isEmpty, isNil]));
const compactAndJoin = pipe(rejectNilOrEmpty, join(' '));
const getInitials = pipe(rejectNilOrEmpty, map(head), join(''));

export default class UserModel extends Model {
  @attr('string')
  email!: string;

  @attr('string')
  firstName!: string | null;

  @attr('string')
  lastName!: string | null;

  @attr('string')
  password?: string;

  @attr('string')
  avatarUrl?: string;

  @hasMany('tenant-membership', { async: false })
  tenantMemberships!: EmberArray<TenantMembershipModel>;

  get fullName() {
    return compactAndJoin([this.firstName, this.lastName]) || this.email;
  }

  get initials() {
    return getInitials([this.firstName, this.lastName]) || '?';
  }

  deactivate = modelAction<
    (opts: Record<'tenant_id', string>) => Promise<unknown>
  >('deactivate', {
    normalizeOperation: 'underscore',
    pushToStore: false,
  });

  changeRole = modelAction<
    (
      opts: Record<'tenant_id', string> & Record<'role', string>
    ) => Promise<unknown>
  >('change-role', {
    normalizeOperation: 'underscore',
    pushToStore: true,
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
