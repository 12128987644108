import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield this.changeset this.save}}", {"contents":"{{yield this.changeset this.save}}","moduleName":"kancast-ui/components/epics/form-container.hbs","parseOptions":{"srcName":"kancast-ui/components/epics/form-container.hbs"}});
import Component from '@glimmer/component';
import { validatePresence } from 'ember-changeset-validations/validators';
import EpicModel from 'kancast-ui/models/epic';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { BufferedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { NotificationsService } from 'services/notifications';

interface Args {
  epic: EpicModel;
  afterSave: Function;
}

const Validations = {
  title: validatePresence(true),
};

export default class extends Component<Args> {
  @service
  notifications!: NotificationsService;

  changeset: BufferedChangeset;

  isNewEpic: boolean;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.isNewEpic = args.epic.isNew as unknown as boolean;

    this.changeset = Changeset(
      args.epic,
      lookupValidator(Validations),
      Validations
    );
  }

  get successText() {
    return this.isNewEpic ? 'Epic has been added' : 'Epic has been updated';
  }

  save = dropTask(async () => {
    await this.changeset.save();

    if (this.changeset.isInvalid) return;

    await this.changeset.save();

    this.notifications.success(this.successText);

    this.args.afterSave(this.args.epic);
  });
}
