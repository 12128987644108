import Controller from '@ember/controller';
import RegisterValidations from '../validations/registration-via-invite';
import { inject as service } from '@ember/service';
import { Router } from '@ember/routing';
import { dropTask, timeout } from 'ember-concurrency';
import InviteModel from 'kancast-ui/models/invite';
import { BufferedChangeset } from 'ember-changeset/types';

export default class InviteController extends Controller {
  RegisterValidations = RegisterValidations;
  password = '';
  passwordConfirmation = '';

  model!: InviteModel;

  @service
  router!: Router;

  @service
  notifications: any;

  register = dropTask(async (cs: BufferedChangeset) => {
    try {
      await cs.validate();

      if (cs.isInvalid) return;

      await this.model.register({ password: cs.get('password') });
      this.notifications.success('Registration completed successfully');
      this.router.transitionTo('login');
    } catch (e) {
      this.notifications.error('Something went wrong');
      throw e;
    }
  });

  approve = dropTask(async () => {
    try {
      await this.model.approve();
      this.notifications.success('Welcome to the team');
      await timeout(1000);
      this.router.transitionTo('tenant', this.model.belongsTo('tenant').id());
    } catch (e) {
      this.notifications.error('Something went wrong');
      throw e;
    }
  });
}
