import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-checkbox class=\"form-group form-check\" ...attributes>\n  <input\n    type=\"checkbox\"\n    class=\"form-check-input\"\n    id={{this.checkboxId}}\n    checked={{@checked}}\n    {{on \"click\" (fn @onChange (not @checked))}}\n  />\n  <label class=\"form-check-label\" for={{this.checkboxId}}>\n    {{yield}}\n  </label>\n</div>", {"contents":"<div data-test-checkbox class=\"form-group form-check\" ...attributes>\n  <input\n    type=\"checkbox\"\n    class=\"form-check-input\"\n    id={{this.checkboxId}}\n    checked={{@checked}}\n    {{on \"click\" (fn @onChange (not @checked))}}\n  />\n  <label class=\"form-check-label\" for={{this.checkboxId}}>\n    {{yield}}\n  </label>\n</div>","moduleName":"kancast-ui/components/checkbox.hbs","parseOptions":{"srcName":"kancast-ui/components/checkbox.hbs"}});
import Component from '@glimmer/component';
import { v4 } from 'uuid';

export default class extends Component {
  checkboxId: string;

  constructor(owner: unknown, args: {}) {
    super(owner, args);

    this.checkboxId = v4();
  }
}
