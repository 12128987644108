import { inject as service } from '@ember/service';
import { Ability } from 'ember-can';
import ProjectModel from 'kancast-ui/models/project';
import CurrentUserService from 'kancast-ui/services/current-user';

export default class ProjectAbility extends Ability {
  @service
  currentUser!: CurrentUserService;

  model!: ProjectModel;

  get canRead() {
    return !!this.membership;
  }

  get canManage() {
    return this.membership?.role === 'owner';
  }

  get canParticipate() {
    return ['owner', 'member'].includes(this.membership?.role as any);
  }

  private get membership() {
    return this.model.members.find(
      ({ user }) => user === this.currentUser.user
    );
  }
}
