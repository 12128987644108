import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsButton\n  data-test-reopen-epic\n  @type=\"info\"\n  @outline={{true}}\n  @size=\"sm\"\n  @onClick={{perform this.epicsManager.reopen @epic}}\n  ...attributes\n>\n  <FaIcon @icon=\"undo\" />\n  Reopen\n</BsButton>", {"contents":"<BsButton\n  data-test-reopen-epic\n  @type=\"info\"\n  @outline={{true}}\n  @size=\"sm\"\n  @onClick={{perform this.epicsManager.reopen @epic}}\n  ...attributes\n>\n  <FaIcon @icon=\"undo\" />\n  Reopen\n</BsButton>","moduleName":"kancast-ui/components/epics/reopen-button.hbs","parseOptions":{"srcName":"kancast-ui/components/epics/reopen-button.hbs"}});
import Component from '@glimmer/component';
import EpicModel from 'kancast-ui/models/epic';
import { inject as service } from '@ember/service';
import EpicsManager from 'kancast-ui/services/epics-manager';

interface Args {
  epic: EpicModel;
}

export default class extends Component<Args> {
  @service
  epicsManager!: EpicsManager;
}
