import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsForm\n  @model={{this.changeset}}\n  @onSubmit={{perform this.save}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-github-integration-name\n    @controlType=\"text\"\n    @label=\"Give your integration a custom name\"\n    @property=\"name\"\n  />\n\n  <form.submitButton data-test-submit>\n    Submit\n  </form.submitButton>\n</BsForm>", {"contents":"<BsForm\n  @model={{this.changeset}}\n  @onSubmit={{perform this.save}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-github-integration-name\n    @controlType=\"text\"\n    @label=\"Give your integration a custom name\"\n    @property=\"name\"\n  />\n\n  <form.submitButton data-test-submit>\n    Submit\n  </form.submitButton>\n</BsForm>","moduleName":"kancast-ui/components/github-integrations/form.hbs","parseOptions":{"srcName":"kancast-ui/components/github-integrations/form.hbs"}});
import Component from '@glimmer/component';
import GithubIntegrationModel from 'kancast-ui/models/github-integration';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validatePresence } from 'ember-changeset-validations/validators';
import { dropTask } from 'ember-concurrency';

interface Args {
  githubIntegration: GithubIntegrationModel;
  afterSave: Function;
}

const validation = {
  name: validatePresence(true),
};

export default class extends Component<Args> {
  changeset: BufferedChangeset;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.changeset = Changeset(
      args.githubIntegration,
      lookupValidator(validation),
      validation
    );
  }

  save = dropTask(async () => {
    await this.changeset.validate();
    if (this.changeset.isInvalid) return;

    await this.changeset.save();

    this.args.afterSave();
  });
}
