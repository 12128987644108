import Model, { belongsTo, attr } from '@ember-data/model';
import UserModel from './user';
import TenantModel from './tenant';

export default class TenantMembershipModel extends Model {
  @attr('string')
  role!: string;

  @belongsTo('user', { async: false })
  user!: UserModel;

  @belongsTo('tenant', { async: false })
  tenant!: TenantModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tenant-membership': TenantMembershipModel;
  }
}
