import emberData__store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ProjectModel from 'kancast-ui/models/project';

export default class extends Route {
  @service
  store!: emberData__store;

  async model() {
    const project: ProjectModel = this.modelFor('tenant.project');

    const reloadedProject = await this.store.findRecord('project', project.id, {
      include: 'github_integrations',
      reload: true,
    });

    return reloadedProject.githubIntegrations;
  }
}
