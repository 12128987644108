import emberData__store from '@ember-data/store';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { SessionService } from 'services/session';

export default class extends Route {
  @service
  session!: SessionService;

  @service
  store!: emberData__store;

  beforeModel(transition: Transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  model() {
    return this.store.createRecord('tenant');
  }
}
