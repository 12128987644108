import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @epic.persistedTickets.length}}\n  <BsButton\n    data-test-start-epic\n    @type=\"primary\"\n    @outline={{true}}\n    @size=\"sm\"\n    @onClick={{perform this.epicsManager.start @epic}}\n    ...attributes\n  >\n    <FaIcon @icon=\"rocket\" />\n    Start\n  </BsButton>\n{{/if}}", {"contents":"{{#if @epic.persistedTickets.length}}\n  <BsButton\n    data-test-start-epic\n    @type=\"primary\"\n    @outline={{true}}\n    @size=\"sm\"\n    @onClick={{perform this.epicsManager.start @epic}}\n    ...attributes\n  >\n    <FaIcon @icon=\"rocket\" />\n    Start\n  </BsButton>\n{{/if}}","moduleName":"kancast-ui/components/epics/start-button.hbs","parseOptions":{"srcName":"kancast-ui/components/epics/start-button.hbs"}});
import Component from '@glimmer/component';
import EpicModel from 'kancast-ui/models/epic';
import { inject as service } from '@ember/service';
import EpicsManager from 'kancast-ui/services/epics-manager';

interface Args {
  epic: EpicModel;
}

export default class extends Component<Args> {
  @service
  epicsManager!: EpicsManager;
}
