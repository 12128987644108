import SimpleAuthSessionService from 'ember-simple-auth/services/session';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import CurrentUserService from '../current-user';

export default class SessionService extends SimpleAuthSessionService {
  @service router!: RouterService;
  @service currentUser!: CurrentUserService;

  async handleAuthentication() {
    await this.currentUser.loadCurrentUser.perform();
    super.handleAuthentication('index');
  }
}
