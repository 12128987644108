import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { AbilitiesService } from 'services/abilities';

export default class IndexRoute extends Route {
  @service
  abilities!: AbilitiesService;

  beforeModel() {
    this.transitionTo('tenant.settings.invites');
  }
}
