import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span>{{this.userRole}}</span>", {"contents":"<span>{{this.userRole}}</span>","moduleName":"kancast-ui/components/user-role.hbs","parseOptions":{"srcName":"kancast-ui/components/user-role.hbs"}});
import Component from '@glimmer/component';
import UserModel from 'kancast-ui/models/user';

interface Args {
  tenant: string;
  user: UserModel;
}

export default class UserRoleComponent extends Component<Args> {
  get userRole() {
    return this.args.user.tenantMemberships.find(
      (m) => m.belongsTo('tenant').id() == this.args.tenant
    )?.role;
  }
}
