import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<nav aria-label=\"pagination\" ...attributes>\n  <ul class=\"pagination\">\n    {{#each this.pageItems as |pageItem|}}\n      <li\n        data-test-page-item\n        data-test-page={{pageItem.page}}\n        class=\"page-item {{if pageItem.active \"active\"}}\"\n      >\n        <a\n          class=\"page-link\"\n          href=\"#\"\n          {{on \"click\" (fn this.changePage pageItem.page)}}\n        >\n          {{pageItem.page}}\n        </a>\n      </li>\n    {{/each}}\n  </ul>\n</nav>", {"contents":"<nav aria-label=\"pagination\" ...attributes>\n  <ul class=\"pagination\">\n    {{#each this.pageItems as |pageItem|}}\n      <li\n        data-test-page-item\n        data-test-page={{pageItem.page}}\n        class=\"page-item {{if pageItem.active \"active\"}}\"\n      >\n        <a\n          class=\"page-link\"\n          href=\"#\"\n          {{on \"click\" (fn this.changePage pageItem.page)}}\n        >\n          {{pageItem.page}}\n        </a>\n      </li>\n    {{/each}}\n  </ul>\n</nav>","moduleName":"kancast-ui/components/pagination.hbs","parseOptions":{"srcName":"kancast-ui/components/pagination.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inc, times } from 'ramda';

interface Args {
  currentPage: number;
  totalItems: number;
  pageSize: number;
  onPageChange: (page: number) => unknown;
}

export default class extends Component<Args> {
  get pageItems() {
    return times(inc, Math.ceil(this.args.totalItems / this.args.pageSize)).map(
      (page) => ({
        page,
        active: page == this.args.currentPage,
      })
    );
  }

  @action
  changePage(page: number, event: Event) {
    event.preventDefault();
    this.args.onPageChange(page);
  }
}
