import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a href={{this.href}} class=\"btn btn-outline-primary d-block\">\n  Sign in with Github\n</a>", {"contents":"<a href={{this.href}} class=\"btn btn-outline-primary d-block\">\n  Sign in with Github\n</a>","moduleName":"kancast-ui/components/oauth/github-button.hbs","parseOptions":{"srcName":"kancast-ui/components/oauth/github-button.hbs"}});
import Component from '@glimmer/component';
import ENV from 'kancast-ui/config/environment';

export default class extends Component {
  get href() {
    return `https://github.com/login/oauth/authorize?client_id=${ENV.githubOAuthClientId}&scope="user repo user:email`;
  }
}
