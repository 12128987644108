import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @epic.areAllTicketsFinished}}\n  <BsButton\n    data-test-finish-epic\n    @type=\"success\"\n    @outline={{true}}\n    @size=\"sm\"\n    @onClick={{perform this.epicsManager.finish @epic}}\n    ...attributes\n  >\n    <FaIcon @icon=\"check\" />\n    Finish\n  </BsButton>\n{{/if}}", {"contents":"{{#if @epic.areAllTicketsFinished}}\n  <BsButton\n    data-test-finish-epic\n    @type=\"success\"\n    @outline={{true}}\n    @size=\"sm\"\n    @onClick={{perform this.epicsManager.finish @epic}}\n    ...attributes\n  >\n    <FaIcon @icon=\"check\" />\n    Finish\n  </BsButton>\n{{/if}}","moduleName":"kancast-ui/components/epics/finish-button.hbs","parseOptions":{"srcName":"kancast-ui/components/epics/finish-button.hbs"}});
import Component from '@glimmer/component';
import EpicModel from 'kancast-ui/models/epic';
import { inject as service } from '@ember/service';
import EpicsManager from 'kancast-ui/services/epics-manager';

interface Args {
  epic: EpicModel;
}

export default class extends Component<Args> {
  @service
  epicsManager!: EpicsManager;
}
