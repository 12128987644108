import Controller from '@ember/controller';
import { action } from '@ember/object';
import GithubIntegrationModel from 'kancast-ui/models/github-integration';

export default class extends Controller {
  model!: GithubIntegrationModel;

  @action
  afterSave() {
    this.transitionToRoute(
      'tenant.project.settings.integrations.github-integration',
      this.model
    );
  }
}
