import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import ProjectModel from 'kancast-ui/models/project';
import { NotificationsService } from 'services/notifications';

export default class extends Controller {
  @service
  notifications!: NotificationsService;

  archive = dropTask(async (project: ProjectModel) => {
    await project.archive();

    this.notifications.success(`Project ${project.name} has been archived`);
  });
}
