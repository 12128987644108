import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.needShowAlarm}}\n  <FaIcon @icon=\"dot-circle\" local-class=\"icon\" ...attributes />\n{{/if}}", {"contents":"{{#if this.needShowAlarm}}\n  <FaIcon @icon=\"dot-circle\" local-class=\"icon\" ...attributes />\n{{/if}}","moduleName":"kancast-ui/components/ticket-alarm-indicator.hbs","parseOptions":{"srcName":"kancast-ui/components/ticket-alarm-indicator.hbs"}});
import Component from '@glimmer/component';
import TicketModel from 'kancast-ui/models/ticket';
import { DateTime } from 'luxon';

interface Args {
  ticket: TicketModel;
  nextStatus: string;
}

export default class extends Component<Args> {
  get needShowAlarm() {
    const prediction = this.args.ticket.predictions?.find(
      (prediction) =>
        prediction.from_status === this.args.ticket.status &&
        prediction.to_status === this.args.nextStatus
    );

    if (!prediction) return false;

    return (
      DateTime.fromJSDate(this.args.ticket.statusChangedAt)
        .plus({
          hours: prediction.prediction,
        })
        .diffNow('hours').hours < 0
    );
  }
}
