import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<TuiEditor\n  @value={{this.value}}\n  @onChange={{this.onChange}}\n  @editType={{@editType}}\n  @hooks={{this.hooks}}\n  @height={{@height}}\n  ...attributes\n/>", {"contents":"<TuiEditor\n  @value={{this.value}}\n  @onChange={{this.onChange}}\n  @editType={{@editType}}\n  @hooks={{this.hooks}}\n  @height={{@height}}\n  ...attributes\n/>","moduleName":"kancast-ui/components/tui-editor-with-file-uploader.hbs","parseOptions":{"srcName":"kancast-ui/components/tui-editor-with-file-uploader.hbs"}});
import Component from '@glimmer/component';
import fetch from 'fetch';
import { v4 as uuidv4 } from 'uuid';
import ENV from 'kancast-ui/config/environment';
import { inject as service } from '@ember/service';
import CurrentUserService from 'kancast-ui/services/current-user';
import { SessionService } from 'services/session';
import { action } from '@ember/object';
import { clone } from 'ramda';

type AddImageBlobHookCallback = (url: string, altText: string) => unknown;

interface Args {
  value: string;
  onChange: (value: string) => unknown;
}

export default class extends Component<Args> {
  @service
  currentUser!: CurrentUserService;

  @service
  session!: SessionService;

  get value() {
    return clone(this.args.value);
  }

  get hooks() {
    return {
      addImageBlobHook: (file: File, callback: AddImageBlobHookCallback) =>
        this.uploadFile(file, callback),
    };
  }

  async uploadFile(file: File, callback: AddImageBlobHookCallback) {
    const tenantId = this.currentUser.currentTenant?.id;
    const formData = new FormData();

    this.changeOkButtonState(true);

    if (!tenantId) throw Error('Current tenant is not defined');

    formData.append('upload', file);
    formData.append('upload_id', uuidv4());
    formData.append('tenant_id', tenantId);

    const serverURL = ENV.serverURL ?? '';
    const response = await fetch(`${serverURL}/api/uploads`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${this.session.data.authenticated.access_token}`,
      },
    });

    const json: Record<'location', string> = await response.json();

    this.changeOkButtonState(false);

    callback(`${serverURL}${json.location}`, file.name);

    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeOkButtonState(disabled: boolean) {
    const okBtn = document.querySelector(
      '.toastui-editor-popup-add-image .toastui-editor-ok-button'
    ) as HTMLButtonElement;
    okBtn.disabled = disabled;
  }

  @action
  onChange(newValue: string) {
    if (newValue !== this.args.value) this.args.onChange(newValue);
  }
}
