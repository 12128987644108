import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#unless this.afterSaveNewComment.isRunning}}\n  <TicketComments::Form\n    class=\"mb-2\"\n    @changeset={{this.newCommentChangeset}}\n    @afterSave={{perform this.afterSaveNewComment}}\n  />\n{{/unless}}\n\n{{#each this.comments as |comment|}}\n  {{#unless comment.isNew}}\n    <TicketComments::CommentBox\n      @comment={{comment}}\n      @deleteComment={{this.deleteComment}}\n    />\n  {{/unless}}\n{{/each}}", {"contents":"{{#unless this.afterSaveNewComment.isRunning}}\n  <TicketComments::Form\n    class=\"mb-2\"\n    @changeset={{this.newCommentChangeset}}\n    @afterSave={{perform this.afterSaveNewComment}}\n  />\n{{/unless}}\n\n{{#each this.comments as |comment|}}\n  {{#unless comment.isNew}}\n    <TicketComments::CommentBox\n      @comment={{comment}}\n      @deleteComment={{this.deleteComment}}\n    />\n  {{/unless}}\n{{/each}}","moduleName":"kancast-ui/components/ticket-comments/container.hbs","parseOptions":{"srcName":"kancast-ui/components/ticket-comments/container.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import TicketModel from 'kancast-ui/models/ticket';
import TicketCommentValidation from 'kancast-ui/validations/ticket-comment';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { BufferedChangeset } from 'ember-changeset/types';
import { dropTask, timeout } from 'ember-concurrency';
import TicketCommentModel from 'kancast-ui/models/ticket-comment';
import { NotificationsService } from 'services/notifications';

interface Args {
  ticket: TicketModel;
}

export default class extends Component<Args> {
  @service
  store!: emberData__store;

  @tracked
  newCommentChangeset: BufferedChangeset;

  @tracked
  hideCommentForm = false;

  @service
  notifications!: NotificationsService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.newCommentChangeset = this.createNewComment();
  }

  get comments() {
    return this.args.ticket.comments.sortBy('insertedAt').reverse();
  }

  afterSaveNewComment = dropTask(async () => {
    await timeout(100);
    this.newCommentChangeset = this.createNewComment();
  });

  deleteComment = dropTask(async (comment: TicketCommentModel) => {
    await comment.destroyRecord();
    this.notifications.success('Comment has been removed');
  });

  private createNewComment() {
    const record = this.store.createRecord('ticket-comment', {
      ticket: this.args.ticket,
    });
    return Changeset(
      record,
      lookupValidator(TicketCommentValidation),
      TicketCommentValidation
    );
  }
}
