import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import EpicModel from 'kancast-ui/models/epic';
import TicketModel from 'kancast-ui/models/ticket';
import { inject as service } from '@ember/service';
import { NotificationsService } from 'services/notifications';
import emberData__store from '@ember-data/store';
import RouterService from '@ember/routing/router-service';

export default class extends Controller {
  model!: EpicModel;

  @service
  notifications!: NotificationsService;

  @service
  store!: emberData__store;

  @service
  router!: RouterService;

  @tracked
  showStartTicketDialog = false;

  @tracked
  ticketToShow?: TicketModel;

  @tracked
  showCumulativeFlowChart = true;

  @tracked
  editMode = false;

  get isInitialStatusNull() {
    return this.model.status === 'planning';
  }

  showTicketDetails = restartableTask(async (ticket: TicketModel) => {
    await this.store.findRecord('ticket', ticket.id, {
      include: 'tags,assignee,reporter,merge_requests',
      reload: true,
    });

    this.ticketToShow = ticket;
  });

  deleteTicket = dropTask(async () => {
    if (!this.ticketToShow) return;

    await this.ticketToShow?.destroyRecord();

    this.notifications.success('The ticket has been removed');
  });

  deleteEpic = dropTask(async () => {
    await this.model.destroyRecord();

    this.router.transitionTo('tenant.project.epics.index');

    this.notifications.success('The epic has been removed');
  });

  @action
  addTicket() {
    this.showStartTicketDialog = true;
  }

  @action
  closeStartTicketDialog() {
    this.showStartTicketDialog = false;
  }

  @action
  hideTicketDetails() {
    this.ticketToShow = undefined;
  }

  @action
  cancelEditing() {
    this.editMode = false;
  }

  @action
  startEditing() {
    this.editMode = true;
  }

  afterAddTicket = dropTask(async () => {
    this.closeStartTicketDialog();
    this.showCumulativeFlowChart = false;
    await timeout(300);
    this.showCumulativeFlowChart = true;
  });
}
