import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsForm\n  @model={{this.changeset}}\n  @onSubmit={{perform this.submit}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-reset-password-email\n    @controlType=\"text\"\n    @label=\"Email\"\n    @property=\"email\"\n  />\n\n  <form.submitButton data-test-reset-password-submit>\n    Send Instructions\n  </form.submitButton>\n</BsForm>", {"contents":"<BsForm\n  @model={{this.changeset}}\n  @onSubmit={{perform this.submit}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-reset-password-email\n    @controlType=\"text\"\n    @label=\"Email\"\n    @property=\"email\"\n  />\n\n  <form.submitButton data-test-reset-password-submit>\n    Send Instructions\n  </form.submitButton>\n</BsForm>","moduleName":"kancast-ui/components/reset-password-request-form.hbs","parseOptions":{"srcName":"kancast-ui/components/reset-password-request-form.hbs"}});
import Component from '@glimmer/component';
import ResetPasswordTokenModel from 'kancast-ui/models/reset-password-token';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import {
  validatePresence,
  validateFormat,
} from 'ember-changeset-validations/validators';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { NotificationsService } from 'services/notifications';

interface Args {
  resetPasswordToken: ResetPasswordTokenModel;
}

const Validations = {
  email: [validatePresence(true), validateFormat({ type: 'email' })],
};

export default class extends Component<Args> {
  @service
  router!: RouterService;

  @service
  notifications!: NotificationsService;

  changeset: BufferedChangeset;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.changeset = Changeset(
      args.resetPasswordToken,
      lookupValidator(Validations),
      Validations
    );
  }

  submit = dropTask(async () => {
    await this.changeset.validate();

    if (this.changeset.isInvalid) return;

    try {
      await this.changeset.save();
      this.router.transitionTo('reset-password-request-sent');
    } catch (e) {
      this.notifications.error('Invalid email');
    }
  });
}
