import Model, { attr, belongsTo } from '@ember-data/model';
import TicketModel from './ticket';

export default class TicketStatusTimeLogModel extends Model {
  @attr('string')
  name!: string;

  @attr('number')
  duration!: number;

  @attr('string')
  fromStatus!: string;

  @attr('string')
  toStatus!: string;

  @attr('number')
  predictedDuration!: number;

  @attr('date')
  insertedAt!: Date;

  @belongsTo('ticket')
  ticket!: TicketModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ticket-status-time-log': TicketStatusTimeLogModel;
  }
}
