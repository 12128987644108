import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsForm\n  @model={{this.changeset}}\n  @onSubmit={{perform this.submit}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-new-tenant-name\n    @controlType=\"text\"\n    @label=\"Name\"\n    @property=\"name\"\n  />\n\n  <form.submitButton data-test-new-tenant-form-submit>\n    Submit\n  </form.submitButton>\n</BsForm>", {"contents":"<BsForm\n  @model={{this.changeset}}\n  @onSubmit={{perform this.submit}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-new-tenant-name\n    @controlType=\"text\"\n    @label=\"Name\"\n    @property=\"name\"\n  />\n\n  <form.submitButton data-test-new-tenant-form-submit>\n    Submit\n  </form.submitButton>\n</BsForm>","moduleName":"kancast-ui/components/new-tenant-form.hbs","parseOptions":{"srcName":"kancast-ui/components/new-tenant-form.hbs"}});
import Component from '@glimmer/component';
import TenantModel from 'kancast-ui/models/tenant';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validatePresence } from 'ember-changeset-validations/validators';
import { dropTask, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import CurrentUserService from 'kancast-ui/services/current-user';
import RouterService from '@ember/routing/router-service';
import { saveChangesetAndSyncErrors } from 'kancast-ui/utils/changeset';
import { NotificationsService } from 'services/notifications';

interface Args {
  tenant: TenantModel;
}

const Validations = {
  name: validatePresence(true),
};

export default class extends Component<Args> {
  @service currentUser!: CurrentUserService;
  @service router!: RouterService;
  @service notifications!: NotificationsService;

  changeset: BufferedChangeset;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.changeset = Changeset(
      args.tenant,
      lookupValidator(Validations),
      Validations
    );
  }

  submit = dropTask(async () => {
    await this.changeset.validate();

    if (this.changeset.isInvalid) return;

    await saveChangesetAndSyncErrors(this.changeset);

    await timeout(1000);
    await this.currentUser.loadCurrentUser.perform();

    this.notifications.success('Your workspace has been created.');

    this.router.transitionTo('tenant.new-project', this.changeset.get('id'));
  });
}
