import emberData__store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import EpicModel from 'kancast-ui/models/epic';
import { NotificationsService } from 'services/notifications';

export default class EpicsManager extends Service {
  @service
  notifications!: NotificationsService;

  @service
  store!: emberData__store;

  finish = dropTask(async (epic: EpicModel) => {
    await epic.finish();

    this.notifications.success(
      `Epic ${epic.scopedIdentifier} has been finished`
    );
  });

  reopen = dropTask(async (epic: EpicModel) => {
    await epic.reopen();

    this.notifications.success(
      `Epic ${epic.scopedIdentifier} has been reopened`
    );
  });

  start = dropTask(async (epic: EpicModel) => {
    await epic.start();

    this.notifications.success(
      `Epic ${epic.scopedIdentifier} has been started`
    );

    await this.store.findRecord('epic', epic.id, {
      include: 'tickets.assignee',
    });
  });
}
