import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showProgressBar}}\n  <BsProgress ...attributes as |pg|>\n    <pg.bar\n      @value={{@epic.finishedTicketsPercent}}\n      @minValue={{0}}\n      @maxValue={{100}}\n      @showLabel={{true}}\n      @roundDigits={{0}}\n      @type=\"success\"\n      @striped={{false}}\n      @animate={{false}}\n    />\n    <BsPopover @placement=\"bottom\" @triggerEvents=\"hover\">\n      <Epics::Stats @epic={{@epic}} />\n    </BsPopover>\n  </BsProgress>\n{{else}}\n  There are no tickets\n{{/if}}", {"contents":"{{#if this.showProgressBar}}\n  <BsProgress ...attributes as |pg|>\n    <pg.bar\n      @value={{@epic.finishedTicketsPercent}}\n      @minValue={{0}}\n      @maxValue={{100}}\n      @showLabel={{true}}\n      @roundDigits={{0}}\n      @type=\"success\"\n      @striped={{false}}\n      @animate={{false}}\n    />\n    <BsPopover @placement=\"bottom\" @triggerEvents=\"hover\">\n      <Epics::Stats @epic={{@epic}} />\n    </BsPopover>\n  </BsProgress>\n{{else}}\n  There are no tickets\n{{/if}}","moduleName":"kancast-ui/components/epics/progress.hbs","parseOptions":{"srcName":"kancast-ui/components/epics/progress.hbs"}});
import Component from '@glimmer/component';
import EpicModel from 'kancast-ui/models/epic';

interface Args {
  epic: EpicModel;
}

export default class extends Component<Args> {
  get showProgressBar() {
    return this.args.epic.persistedTickets.length > 0;
  }
}
