import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsForm\n  @model={{@changeset}}\n  @onSubmit={{perform this.submit}}\n  @hideValidationsOnSubmit={{true}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-edit-comment-text\n    @invisibleLabel={{true}}\n    @controlType=\"text\"\n    @label=\"Comment text\"\n    @property=\"text\"\n  >\n    <TuiEditorWithFileUploader\n      @height=\"160px\"\n      @value={{@changeset.text}}\n      @onChange={{fn (mut @changeset.text)}}\n      @editType=\"wysiwyg\"\n    />\n  </form.element>\n  <form.submitButton data-test-submit-comment-form>\n    Submit\n  </form.submitButton>\n  {{#if @onCancel}}\n    <BsButton\n      data-test-cancel-editing\n      @outline=\"true\"\n      @type=\"secondary\"\n      @onClick={{@onCancel}}\n    >\n      Cancel\n    </BsButton>\n  {{/if}}\n</BsForm>", {"contents":"<BsForm\n  @model={{@changeset}}\n  @onSubmit={{perform this.submit}}\n  @hideValidationsOnSubmit={{true}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-edit-comment-text\n    @invisibleLabel={{true}}\n    @controlType=\"text\"\n    @label=\"Comment text\"\n    @property=\"text\"\n  >\n    <TuiEditorWithFileUploader\n      @height=\"160px\"\n      @value={{@changeset.text}}\n      @onChange={{fn (mut @changeset.text)}}\n      @editType=\"wysiwyg\"\n    />\n  </form.element>\n  <form.submitButton data-test-submit-comment-form>\n    Submit\n  </form.submitButton>\n  {{#if @onCancel}}\n    <BsButton\n      data-test-cancel-editing\n      @outline=\"true\"\n      @type=\"secondary\"\n      @onClick={{@onCancel}}\n    >\n      Cancel\n    </BsButton>\n  {{/if}}\n</BsForm>","moduleName":"kancast-ui/components/ticket-comments/form.hbs","parseOptions":{"srcName":"kancast-ui/components/ticket-comments/form.hbs"}});
import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { NotificationsService } from 'services/notifications';

interface Args {
  changeset: BufferedChangeset;
  afterSave?: Function;
}

export default class extends Component<Args> {
  @service
  notifications!: NotificationsService;

  submit = dropTask(async () => {
    try {
      await this.args.changeset.validate();
      if (this.args.changeset.isInvalid) return;

      await this.args.changeset.save();

      if (this.args.afterSave) this.args.afterSave();
    } catch (e) {
      this.notifications.error('Something went wrong');
      throw e;
    }
  });
}
