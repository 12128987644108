import { BufferedChangeset } from 'ember-changeset/types';

interface DSError {
  attribute: string;
  message: string;
}

export const maybeAddModelErrors = (cs: BufferedChangeset) => {
  const data = cs.data as { errors?: DSError[] };

  if (!data.errors?.length) return false;

  data.errors.forEach(({ attribute, message }) =>
    cs.addError(attribute, message)
  );

  return true;
};

export const saveChangesetAndSyncErrors = async (cs: BufferedChangeset) => {
  try {
    await cs.save();
    return cs;
  } catch (e) {
    maybeAddModelErrors(cs);
    throw e;
  }
};
