import Controller from '@ember/controller';
import { dropTask } from 'ember-concurrency';
import GithubIntegrationModel from 'kancast-ui/models/github-integration';
import { inject as service } from '@ember/service';
import { NotificationsService } from 'services/notifications';

export default class extends Controller {
  @service
  notifications!: NotificationsService;

  model!: GithubIntegrationModel;

  delete = dropTask(async () => {
    await this.model.destroyRecord();
    this.notifications.success('The integration has been deleted');
    this.transitionToRoute('tenant.project.settings.integrations.index');
  });
}
