import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { SessionService } from 'services/session';
import { run } from '@ember/runloop';

export default class extends Route {
  @service session!: SessionService;

  queryParams = {
    code: {
      replace: true,
    },
  };

  model(params: Record<'code', string>) {
    run(async () => {
      this.session
        .authenticate('authenticator:code', params.code, 'github')
        .catch(() => this.transitionTo('500'));
    });
  }
}
