import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsForm\n  @model={{this.changeset}}\n  @onSubmit={{perform this.submit}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-password\n    @controlType=\"password\"\n    @label=\"New Password\"\n    @property=\"password\"\n  />\n\n  <form.element\n    data-test-password-confirmation\n    @controlType=\"password\"\n    @label=\"New Password Confirmation\"\n    @property=\"passwordConfirmation\"\n  />\n\n  <form.submitButton data-test-reset-password-submit>\n    Reset Password\n  </form.submitButton>\n</BsForm>", {"contents":"<BsForm\n  @model={{this.changeset}}\n  @onSubmit={{perform this.submit}}\n  ...attributes as |form|\n>\n  <form.element\n    data-test-password\n    @controlType=\"password\"\n    @label=\"New Password\"\n    @property=\"password\"\n  />\n\n  <form.element\n    data-test-password-confirmation\n    @controlType=\"password\"\n    @label=\"New Password Confirmation\"\n    @property=\"passwordConfirmation\"\n  />\n\n  <form.submitButton data-test-reset-password-submit>\n    Reset Password\n  </form.submitButton>\n</BsForm>","moduleName":"kancast-ui/components/reset-password-form.hbs","parseOptions":{"srcName":"kancast-ui/components/reset-password-form.hbs"}});
import Component from '@glimmer/component';
import {
  validatePresence,
  validateConfirmation,
  validateLength,
} from 'ember-changeset-validations/validators';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import ResetPasswordTokenModel from 'kancast-ui/models/reset-password-token';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { NotificationsService } from 'services/notifications';
import RouterService from '@ember/routing/router-service';

const Validations = {
  password: [validatePresence(true), validateLength({ min: 6 })],
  passwordConfirmation: validateConfirmation({ on: 'password' }),
};

interface Args {
  token: ResetPasswordTokenModel;
}

export default class extends Component<Args> {
  @service
  notifications!: NotificationsService;

  @service
  router!: RouterService;

  changeset: BufferedChangeset;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.changeset = Changeset({}, lookupValidator(Validations), Validations);
  }

  submit = dropTask(async () => {
    try {
      await this.args.token.resetPassword({
        password: this.changeset.get('password'),
      });

      this.notifications.success('Your password has been changed');

      this.router.transitionTo('login');
    } catch (e) {
      this.notifications.error(
        'This link is expired. Try to resend reset password instructions'
      );
      this.router.transitionTo('reset-password-request');
    }
  });
}
