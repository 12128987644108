import Model, { attr } from '@ember-data/model';
import { modelAction } from 'ember-custom-actions';

export default class ResetPasswordTokenModel extends Model {
  @attr('string')
  email!: string;

  @attr('boolean')
  used!: string;

  @attr('number')
  ttl!: number;

  @attr('date')
  insertedAt!: Date;

  resetPassword = modelAction<
    (opts: Record<'password', string>) => Promise<unknown>
  >('reset-password', {
    normalizeOperation: 'underscore',
    pushToStore: false,
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reset-password-token': ResetPasswordTokenModel;
  }
}
