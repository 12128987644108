import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Filters::Container\n  @filter={{@filter}}\n  @setFilter={{@setFilter}} as |toggleFilter|\n>\n  <Filters::FilterByMembers\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @members={{this.members}}\n  />\n  <Filters::FilterByTags\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @tags={{@project.tags}}\n  />\n  <Filters::FilterByEpics\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @epics={{@project.epics}}\n  />\n</Filters::Container>", {"contents":"<Filters::Container\n  @filter={{@filter}}\n  @setFilter={{@setFilter}} as |toggleFilter|\n>\n  <Filters::FilterByMembers\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @members={{this.members}}\n  />\n  <Filters::FilterByTags\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @tags={{@project.tags}}\n  />\n  <Filters::FilterByEpics\n    @filter={{@filter}}\n    @toggleFilter={{toggleFilter}}\n    @epics={{@project.epics}}\n  />\n</Filters::Container>","moduleName":"kancast-ui/components/kanban-board/filters.hbs","parseOptions":{"srcName":"kancast-ui/components/kanban-board/filters.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import UserModel from 'kancast-ui/models/user';
import { KanbanBoardFilter } from 'kancast-ui/utils/kanban-board-filter';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';
import ProjectModel from 'kancast-ui/models/project';

interface Args {
  filter: KanbanBoardFilter;
  project: ProjectModel;
  setFilter: (filter: KanbanBoardFilter) => unknown;
}

export default class extends Component<Args> {
  @service
  store!: emberData__store;

  @tracked
  members: UserModel[];

  unassinged = Object.freeze({ id: 'unassigned' });

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.members = args.project.members.map((m) => m.user);
  }
}
