import { WorkflowItem } from 'kancast-ui/models/project';
import { Prediction } from 'kancast-ui/models/ticket';
import { DateTime } from 'luxon';
import { find, eqProps, allPass, defaultTo, mapAccum, pipe } from 'ramda';

const ONE_DAY = 24;

const defaultPrediction = (workflowItem: WorkflowItem) =>
  defaultTo({
    prediction: ONE_DAY,
    from_status: workflowItem.from_status,
    to_status: workflowItem.to_status,
    notPredicted: true,
  });

type MaybeDefaultPrediction = Prediction & Record<'notPredicted', boolean>;

export interface Timeline {
  fromStatus: string;
  toStatus: string;
  duration: number;
  predicted: boolean;
  date: DateTime;
}

const buildTimelineItem =
  (startDate: DateTime) =>
  (prediction: MaybeDefaultPrediction): Timeline => ({
    fromStatus: prediction.from_status,
    toStatus: prediction.to_status,
    duration: prediction.prediction,
    predicted: !prediction.notPredicted,
    date: startDate.plus({ hours: prediction.prediction || ONE_DAY }),
  });

const findPrediction = (workflowItem: WorkflowItem) =>
  pipe(
    find(
      allPass([
        eqProps('from_status', workflowItem),
        eqProps('to_status', workflowItem),
      ])
    ),
    defaultPrediction(workflowItem)
  );

const mapWorkflowItem =
  (predictions: Prediction[]) =>
  (startData: DateTime, workflowItem: WorkflowItem): [DateTime, Timeline] => {
    const timelineItem = pipe(
      findPrediction(workflowItem),
      buildTimelineItem(startData)
    )(predictions);

    return [timelineItem.date, timelineItem];
  };

const sliceWorkflowToStartPosition = (
  workflow: WorkflowItem[],
  startStatus: string | undefined
) => {
  if (!startStatus) return workflow;
  const firstItem = workflow.find((item) => item.from_status === startStatus);
  if (!firstItem) return workflow;
  return workflow.slice(workflow.indexOf(firstItem), Infinity);
};

export const generateTicketTimeLine = (
  predictions: Prediction[],
  workflow: WorkflowItem[],
  startTimestamp?: DateTime,
  startStatus?: string
) => {
  const [, timeline] = mapAccum(
    mapWorkflowItem(predictions),
    startTimestamp || DateTime.now(),
    sliceWorkflowToStartPosition(workflow, startStatus)
  );
  return timeline;
};
