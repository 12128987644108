import Service, { inject as service } from '@ember/service';
import { SessionService } from 'services/session';
import fetch from 'fetch';

export default class HttpService extends Service {
  @service
  session!: SessionService;

  defaultHeaders = {
    'Content-Type': 'application/vnd.api+json',
  };

  fetch(url: string, options: RequestInit = {}) {
    return fetch(url, {
      headers: this.headers,
      ...options,
    });
  }

  get headers() {
    if (this.session.isAuthenticated) {
      return {
        ...this.defaultHeaders,
        Authorization: `Bearer ${this.session.data.authenticated.access_token}`,
      };
    }

    return this.defaultHeaders;
  }
}
