import emberData__store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service
  store!: emberData__store;

  model(params: { github_integration_id: string }) {
    return this.store.findRecord(
      'github-integration',
      params.github_integration_id
    );
  }
}
