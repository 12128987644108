import emberData__store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service
  store!: emberData__store;

  model(params: { reset_password_token_id: string }) {
    return this.store.findRecord(
      'reset-password-token',
      params.reset_password_token_id
    );
  }
}
