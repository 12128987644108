import Route from '@ember/routing/route';
import TenantModel from 'kancast-ui/models/tenant';
import { isNil, map, pipe, uniq, prop, reject } from 'ramda';
import { inject as service } from '@ember/service';
import emberData__store from '@ember-data/store';

const getEmail = pipe(map(prop('email')), uniq, reject(isNil));

export default class IndexRoute extends Route {
  @service
  store!: emberData__store;

  async model() {
    const tenant: TenantModel = this.modelFor('tenant');
    const [invites, projects] = await Promise.all([
      this.store.query('invite', {
        filter: { tenant_id: tenant.id },
      }),
      this.store.query('project', { filter: { tenant_id: tenant.id } }),
    ]);

    const users = this.store.peekAll('user').toArray();

    return {
      invite: this.store.createRecord('invite', { tenant: tenant }),
      projects: projects,
      usedEmails: getEmail([...invites.toArray(), ...users]),
    };
  }
}
