import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n  <b>\n    Total story points:\n  </b>\n  <span data-test-total-story-points>\n    {{this.totalStoryPoints}}\n  </span>\n</div>", {"contents":"<div ...attributes>\n  <b>\n    Total story points:\n  </b>\n  <span data-test-total-story-points>\n    {{this.totalStoryPoints}}\n  </span>\n</div>","moduleName":"kancast-ui/components/ticket-total-story-points.hbs","parseOptions":{"srcName":"kancast-ui/components/ticket-total-story-points.hbs"}});
import Component from '@glimmer/component';
import { Estimation } from 'kancast-ui/models/ticket';
import { sum, prop } from 'ramda';

interface Args {
  estimations: Estimation[];
}

export default class extends Component<Args> {
  get totalStoryPoints() {
    return sum(this.args.estimations.map(prop('value')));
  }
}
