import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import CurrentUserService from 'kancast-ui/services/current-user';
import { SessionService } from 'services/session';

export default class ApplicationRoute extends Route {
  @service
  currentUser!: CurrentUserService;

  @service
  session!: SessionService;

  @service
  router!: RouterService;

  beforeModel(transition: Transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  afterModel() {
    const { user } = this.currentUser;
    const firstTenantId = user?.tenantMemberships?.firstObject
      ?.belongsTo('tenant')
      ?.id();
    if (firstTenantId) {
      this.router.transitionTo('tenant', firstTenantId);
    } else {
      this.router.transitionTo('new-tenant');
    }
  }
}
