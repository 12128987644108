import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Checkbox @checked={{this.checked}} @onChange={{@onClick}} ...attributes>\n  {{yield}}\n</Checkbox>", {"contents":"<Checkbox @checked={{this.checked}} @onChange={{@onClick}} ...attributes>\n  {{yield}}\n</Checkbox>","moduleName":"kancast-ui/components/filters/filter-option.hbs","parseOptions":{"srcName":"kancast-ui/components/filters/filter-option.hbs"}});
import Component from '@glimmer/component';

interface Args {
  option: unknown;
  selectedOptions: unknown[];
  label: string;
  onClick: (option: unknown) => unknown;
}

export default class extends Component<Args> {
  get checked() {
    return this.args.selectedOptions.includes(this.args.option);
  }
}
