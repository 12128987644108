export enum Color {
  BLUE = '#0d6efd',
  INDIGO = '#6610f2',
  PURPLE = '#6f42c1',
  PINK = '#d63384',
  RED = '#dc3545',
  ORANGE = '#fd7e14',
  YELLOW = '#ffc107',
  GREEN = '#198754',
  TEAL = '#20c997',
  CYAN = '#0dcaf0',
  GREY = '#adb5bd',
  BLACK = '#000',
}

export const DARK_COLORS = [
  Color.BLACK,
  Color.INDIGO,
  Color.PURPLE,
  Color.PINK,
  Color.RED,
  Color.GREEN,
  Color.BLACK,
];

export const LIGHT_COLORS = [
  Color.ORANGE,
  Color.YELLOW,
  Color.TEAL,
  Color.CYAN,
  Color.GREY,
];
