import Model, { attr } from '@ember-data/model';

export default class ProjectPredictionStatModel extends Model {
  @attr('string')
  email!: string;

  @attr('string')
  fromStatus!: string;

  @attr('string')
  toStatus!: string;

  @attr('number')
  errorMean!: number;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-prediction-stat': ProjectPredictionStatModel;
  }
}
