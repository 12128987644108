import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"badge {{this.badgeClass}}\" local-class=\"with-radius\" ...attributes>\n  {{@value}}\n</span>", {"contents":"<span class=\"badge {{this.badgeClass}}\" local-class=\"with-radius\" ...attributes>\n  {{@value}}\n</span>","moduleName":"kancast-ui/components/estimation-badge.hbs","parseOptions":{"srcName":"kancast-ui/components/estimation-badge.hbs"}});
import Component from '@glimmer/component';

interface Args {
  value: number;
}

export default class EstimationBadgeComponent extends Component<Args> {
  get badgeClass() {
    if (this.args.value <= 4) return 'bg-success';
    if (this.args.value <= 7) return 'bg-warning';
    return 'bg-danger';
  }
}
