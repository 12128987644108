import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<HighCharts @chartOptions={{this.chartOptions}} @content={{this.charData}} />", {"contents":"<HighCharts @chartOptions={{this.chartOptions}} @content={{this.charData}} />","moduleName":"kancast-ui/components/epics/cumulative-flow-chart.hbs","parseOptions":{"srcName":"kancast-ui/components/epics/cumulative-flow-chart.hbs"}});
import Component from '@glimmer/component';
import { getEpicFinishDateEstimation } from 'kancast-ui/helpers/epic-finish-date-estimation';
import EpicModel from 'kancast-ui/models/epic';
import TicketModel from 'kancast-ui/models/ticket';
import {
  generateTicketTimeLine,
  Timeline,
} from 'kancast-ui/utils/generate-ticket-timeline';
import { DateTime } from 'luxon';
import {
  groupBy,
  identity,
  map,
  pipe,
  reverse,
  times,
  filter,
  last,
} from 'ramda';

interface Args {
  epic: EpicModel;
}

const getTicketDateForTimeline = (t: TicketModel) =>
  t.status ? t.statusChangedAt || t.startedAt : undefined;

const statsForDate = (timelines: Timeline[][]) => (date: DateTime) =>
  pipe(
    map(
      (ticketTimeline: Timeline[]) =>
        reverse(ticketTimeline).find((tl) => {
          return date.diff(tl.date, 'hours').hours > 0;
        })?.toStatus || ticketTimeline[0]?.fromStatus
    ),
    groupBy(identity)
  )(timelines);

export default class extends Component<Args> {
  get chartOptions() {
    return {
      chart: {
        type: 'area',
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: this.dates.map((dt) => dt.toLocaleString()),
        tickmarkPlacement: 'on',
        title: {
          enabled: false,
        },
      },
      yAxis: {
        title: {
          text: 'Tickets',
        },
      },
      tooltip: {
        split: true,
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: '#666666',
          },
        },
      },
    };
  }

  get charData() {
    const aggregation = this.dates.map(statsForDate(this.ticketsTimelines));

    const sortedTicketBuckets =
      this.args.epic.project.ticketBuckets.sortBy('position');

    const lastBucketName = last(sortedTicketBuckets)?.name;
    const finishedTicketsCount = this.args.epic.persistedTickets.filterBy(
      'isFinished',
      true
    ).length;

    return sortedTicketBuckets.map((bucket) => ({
      name: bucket.name,
      data: aggregation.map(
        (stats) =>
          (stats[bucket.name]?.length || 0) +
          (lastBucketName === bucket.name ? finishedTicketsCount : 0)
      ),
    }));
  }

  get ticketsTimelines() {
    const epicStartedAt = this.args.epic.startedAt || new Date();
    return this.args.epic.persistedTickets
      .filterBy('isFinished', false)
      .map((t) =>
        generateTicketTimeLine(
          t.predictions || [],
          t.project.workflow || [],
          DateTime.fromJSDate(getTicketDateForTimeline(t) || epicStartedAt),
          t.status
        )
      );
  }

  get dates() {
    const now = DateTime.now();
    const epicEstimatedFinishedDate = getEpicFinishDateEstimation(
      this.args.epic
    );
    const interval = Math.ceil(
      epicEstimatedFinishedDate.diff(now, 'days').days / 10
    );
    return [
      ...pipe(
        times(identity),
        map((index) => now.plus({ days: interval * index }).endOf('day')),
        filter(
          (dt: DateTime) => dt.diff(epicEstimatedFinishedDate).milliseconds < 0
        )
      )(10),
      epicEstimatedFinishedDate.endOf('day'),
    ];
  }
}
