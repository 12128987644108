import Model, { attr, belongsTo } from '@ember-data/model';
import TicketModel from './ticket';
import UserModel from './user';

export default class TicketCommentModel extends Model {
  @attr('string', { defaultValue: '' })
  text!: string;

  @attr('date')
  insertedAt!: Date;

  @attr('date')
  updatedAt!: Date;

  @belongsTo('user')
  author!: UserModel;

  @belongsTo('ticket', { async: false })
  ticket!: TicketModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ticket-comment': TicketCommentModel;
  }
}
