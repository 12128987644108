import Route from '@ember/routing/route';
import TenantModel from 'kancast-ui/models/tenant';
import { inject as service } from '@ember/service';
import { AbilitiesService } from 'services/abilities';
import Transition from '@ember/routing/-private/transition';
import emberData__store from '@ember-data/store';

export default class NewProjectRoute extends Route {
  @service
  abilities!: AbilitiesService;

  @service
  store!: emberData__store;

  beforeModel(transition: Transition) {
    const tenant: TenantModel = this.modelFor('tenant');

    if (this.abilities.cannot('manage tenant', tenant)) {
      transition.abort();
      this.transitionTo('tenant.401', tenant);
    }
  }

  model() {
    const tenant: TenantModel = this.modelFor('tenant');
    return this.store.createRecord('project', {
      tenant: tenant,
      ticketBuckets: [],
      estimationCriteria: ['api', 'ui'],
      customFields: [],
    });
  }
}
