import Transform from '@ember-data/serializer/transform';
import { Estimation, EstimationData } from 'kancast-ui/models/ticket';

export const serialize = (array: Estimation[]) =>
  array?.map((el) => ({
    name: el.name,
    value: el.value,
  }));

export default class EstimationTransform extends Transform {
  deserialize(array: EstimationData[]) {
    return array?.map((obj) => new Estimation(obj.name, obj.value));
  }

  serialize = serialize;
}
