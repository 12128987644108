import { helper } from '@ember/component/helper';
import EpicModel from 'kancast-ui/models/epic';
import TicketModel from 'kancast-ui/models/ticket';
import { generateTicketTimeLine } from 'kancast-ui/utils/generate-ticket-timeline';
import { DateTime } from 'luxon';
import { head, last, pipe } from 'ramda';

const getTicketEstimatedFinishedDate =
  (startedAt: DateTime) => (t: TicketModel) =>
    last(
      generateTicketTimeLine(
        t.predictions || [],
        t.project.workflow || [],
        (t.status && t.statusChangedAt
          ? DateTime.fromJSDate(t.statusChangedAt)
          : null) || startedAt,
        t.status
      )
    )?.date || DateTime.now();

export const getEpicFinishDateEstimation = (epic: EpicModel) => {
  if (epic.areAllTicketsFinished) return DateTime.now();
  const startedAt =
    epic.status === 'planning'
      ? DateTime.now()
      : DateTime.fromJSDate(epic.startedAt || new Date());

  return (
    DateTime.max(
      ...epic.tickets
        .filter((t) => !t.isFinished)
        .map(getTicketEstimatedFinishedDate(startedAt))
    ) || startedAt
  );
};

const toLocaleString = (dt?: DateTime) => dt?.toLocaleString();

export default helper(pipe(head, getEpicFinishDateEstimation, toLocaleString));
