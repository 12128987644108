import Model, { attr, belongsTo } from '@ember-data/model';
import TicketModel from './ticket';

export default class ScmMergeRequestModel extends Model {
  @attr('string')
  title!: string;

  @attr('string')
  state!: string;

  @attr('string')
  url!: string;

  @attr('string')
  description!: string;

  @attr()
  payload!: {
    base?: {
      repo?: {
        name?: string;
      };
    };
  };

  @belongsTo('ticket', { async: false })
  ticket!: TicketModel;

  get repoName() {
    return this.payload?.base?.repo?.name;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scm-merge-request': ScmMergeRequestModel;
  }
}
