import emberData__store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service
  store!: emberData__store;

  model(params: Record<'epic_id', string>) {
    return this.store.findRecord('epic', params.epic_id, {
      include: 'tickets.assignee,project',
    });
  }
}
