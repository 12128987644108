import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"container\" ...attributes>\n  {{#each this.colors as |color|}}\n    {{! template-lint-disable no-inline-styles }}\n    {{! template-lint-disable style-concatenation }}\n    <div\n      data-test-tag-color={{color}}\n      role=\"button\"\n      style=\"background-color: {{color}};\"\n      local-class=\"color-options\"\n      {{on \"click\" (fn @onChange color)}}\n    >\n      {{#if (eq @selected color)}}\n        <FaIcon @icon=\"check\" local-class=\"icon\" />\n      {{/if}}\n    </div>\n  {{/each}}\n</div>", {"contents":"<div local-class=\"container\" ...attributes>\n  {{#each this.colors as |color|}}\n    {{! template-lint-disable no-inline-styles }}\n    {{! template-lint-disable style-concatenation }}\n    <div\n      data-test-tag-color={{color}}\n      role=\"button\"\n      style=\"background-color: {{color}};\"\n      local-class=\"color-options\"\n      {{on \"click\" (fn @onChange color)}}\n    >\n      {{#if (eq @selected color)}}\n        <FaIcon @icon=\"check\" local-class=\"icon\" />\n      {{/if}}\n    </div>\n  {{/each}}\n</div>","moduleName":"kancast-ui/components/tag/color-picker.hbs","parseOptions":{"srcName":"kancast-ui/components/tag/color-picker.hbs"}});
import Component from '@glimmer/component';
import { Color } from 'kancast-ui/constants/color';

interface Args {
  selected: string;
}

export default class extends Component<Args> {
  get colors() {
    return Object.values(Color);
  }
}
