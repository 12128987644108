import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import CurrentUserService from 'kancast-ui/services/current-user';

export default class extends Route {
  @service
  session!: any;

  @service
  currentUser!: CurrentUserService;

  beforeModel(transition: Transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  model() {
    return this.currentUser.user;
  }
}
