import emberData__store from '@ember-data/store';
import { Router } from '@ember/routing';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import TenantModel from 'kancast-ui/models/tenant';
import CurrentUserService from 'kancast-ui/services/current-user';
import { SessionService } from 'services/session';

export default class TenantRoute extends Route {
  @service
  router!: Router;

  @service
  currentUser!: CurrentUserService;

  @service
  session!: SessionService;

  @service
  store!: emberData__store;

  beforeModel(transition: Transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  model(params: Record<'tenant_id', string>) {
    return this.store.findRecord('tenant', params.tenant_id, {
      include: 'tenant_memberships.user',
      reload: true,
    });
  }

  afterModel(model: TenantModel) {
    this.currentUser.currentTenant = model;
  }
}
