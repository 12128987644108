import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<KanbanBoard::Filters\n  @project={{@project}}\n  @filter={{this.currentFilter}}\n  @setFilter={{fn (mut this.currentFilter)}}\n/>\n<section local-class=\"board\">\n  {{#each this.sortedTicketBuckets as |bucket index|}}\n    <KanbanColumn\n      data-test-ticket-bucket={{bucket.name}}\n      @bucketName={{bucket.name}}\n      @columnIndex={{index}}\n      @sortedTicketBuckets={{this.sortedTicketBuckets}}\n      @startTicket={{this.startTicket}}\n      @projectId={{@project.id}}\n      @project={{@project}}\n      @selectedAssignee={{this.selectedAssignee}}\n      @filter={{this.currentFilter}}\n    />\n  {{/each}}\n</section>\n{{#if this.showStartTicketDialog}}\n  <StartTicketModal\n    @project={{@project}}\n    @open={{this.showStartTicketDialog}}\n    @onHidden={{this.closeStartTicketDialog}}\n    @afterSave={{this.closeStartTicketDialog}}\n  />\n{{/if}}", {"contents":"<KanbanBoard::Filters\n  @project={{@project}}\n  @filter={{this.currentFilter}}\n  @setFilter={{fn (mut this.currentFilter)}}\n/>\n<section local-class=\"board\">\n  {{#each this.sortedTicketBuckets as |bucket index|}}\n    <KanbanColumn\n      data-test-ticket-bucket={{bucket.name}}\n      @bucketName={{bucket.name}}\n      @columnIndex={{index}}\n      @sortedTicketBuckets={{this.sortedTicketBuckets}}\n      @startTicket={{this.startTicket}}\n      @projectId={{@project.id}}\n      @project={{@project}}\n      @selectedAssignee={{this.selectedAssignee}}\n      @filter={{this.currentFilter}}\n    />\n  {{/each}}\n</section>\n{{#if this.showStartTicketDialog}}\n  <StartTicketModal\n    @project={{@project}}\n    @open={{this.showStartTicketDialog}}\n    @onHidden={{this.closeStartTicketDialog}}\n    @afterSave={{this.closeStartTicketDialog}}\n  />\n{{/if}}","moduleName":"kancast-ui/components/kanban-board.hbs","parseOptions":{"srcName":"kancast-ui/components/kanban-board.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { sortBy, prop } from 'ramda';
import { action } from '@ember/object';
import ProjectModel from 'kancast-ui/models/project';
import UserModel from 'kancast-ui/models/user';
import { KanbanBoardFilter } from 'kancast-ui/utils/kanban-board-filter';

const sortByPosition = sortBy(prop('position'));

interface Args {
  project: ProjectModel;
}

export default class KanbanBoardComponent extends Component<Args> {
  @tracked
  showStartTicketDialog = false;

  @tracked
  selectedAssignee?: UserModel;

  @tracked
  currentFilter: KanbanBoardFilter;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.currentFilter = {
      users: [],
      epics: [],
      tags: [],
    };
  }

  get sortedTicketBuckets() {
    return sortByPosition(this.args.project.ticketBuckets);
  }

  @action
  startTicket() {
    this.showStartTicketDialog = true;
  }

  @action
  closeStartTicketDialog() {
    this.showStartTicketDialog = false;
  }
}
