import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import CurrentUserService from 'kancast-ui/services/current-user';
import { dropTask } from 'ember-concurrency';
import UserModel from 'kancast-ui/models/user';
import { NotificationsService } from 'services/notifications';

export default class UserController extends Controller {
  @service currentUser!: CurrentUserService;
  @service notifications!: NotificationsService;

  @tracked
  sortBy?: string = 'role';

  get sortedUsers() {
    return this.model.users.sortBy(this.sortBy);
  }

  @action
  changeSortBy(val: string) {
    this.sortBy = val;
  }

  deactivateUser = dropTask(async (user: UserModel) => {
    await user.deactivate({
      tenant_id: this.currentUser.currentTenant?.id as string,
    });

    this.notifications.success(
      `${user.fullName} membership has been deactivated. This user don't have access to your tenant anymore`
    );

    user.unloadRecord();
  });
}
